import { Box, Button, CircularProgress, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAxios } from '../utils'
import { PiSealCheckFill } from "react-icons/pi"
import { RxCrossCircled } from "react-icons/rx"
import { useAuth } from '../auth'

export const ResendMailVerification = () => {
    const [status,setStatus] = useState('main')

    const axiosInstance = useAxios()
    const {user} = useAuth()

    const sendEmail = () => {
        setStatus('sending')
        axiosInstance.post('/api/sendVerificationEmail')
        .then(resp => {
            setStatus('success')
        })
        .catch(e=>{
            console.log(e)
            setStatus('failed')
        })
    }
  return (
    <Container>
        <Box sx={{display:'flex',justifyContent:'center'}}>
            <Paper sx={{width:{xs:'100%',md:600}, px:{xs:1,md:6}, pb:3, pt:2}}>
                <Stack spacing={1} sx={{}}>

                <Typography align='center' variant='h6' sx={{color:'#0088ff', fontWeight:'bold'}}>VALIDER VOTRE EMAIL !</Typography>
                    { status === 'main' &&
                        <>
                        <Typography mt={2} > Veuillez verifier votre email et valider que c'est bien votre email!</Typography>
                        <Box>
                            <Typography>Si vous n'avez pas encore recue l'email</Typography>
                            <Button sx={{mt:1}} onClick={sendEmail}>Renvoyer l'email</Button>
                        </Box>
                        </>
                    }
                    { status === 'sending' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <CircularProgress sx={{color: '#fff', fontSize:15}}/>
                            <Box sx={{ml:2}}>
                                <Typography >Envoi de l'email...</Typography>
                                <Typography>Cela peut prendre jusqu'a 15 minutes. Veuillez patientez!</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                    { status === 'success' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <PiSealCheckFill color='green' size={50}/>
                            <Box sx={{ml:2}}>
                                <Typography color="danger" >Email envoyer!</Typography>
                                <Typography>Veuillez verifier votre email</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                    { status === 'failed' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <RxCrossCircled color='red' size={50}/>
                            <Box sx={{ml:2}}>
                                <Typography color="danger">Erreur sur la comfirmation, Veuillez reessayer Plus tard !</Typography>
                                <Typography>Si l'erreur persiste, veuillez nous contacter sur : </Typography>
                                <Typography align='center' sx={{fontWeight:'bold'}}>support@mvxchain.com</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                </Stack>
            </Paper>
        </Box>
    </Container>
  )
}
