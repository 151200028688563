import React from 'react'
import { PiSealCheckFill } from "react-icons/pi"
import { RxCrossCircled } from "react-icons/rx"
import { PiSealWarningFill } from "react-icons/pi"
import { TbProgress } from "react-icons/tb"

export const StatusIcon = (props) => {
    const {severity} = props
  return (
    <>
    {severity === 'success' && <PiSealCheckFill color='#198754' {...props}/>}
    {severity === 'error' && <RxCrossCircled color='#dc3545'{...props}/>}
    {severity === 'warning' && <PiSealWarningFill color='#ffc107' {...props}/>}
    {severity === 'progressing' && <TbProgress color='#adb5bd' {...props}/>}

    </>
  )
}
