import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { numberFormatter, roundNumber } from '../../utils'
import { convertDayJs } from '../../utils/service'

export const HistoryByType = ({currentType,transaction,wallet}) => {
    const type = () => {
        if(transaction.type === 'buy'){
            return 'Acheter'
        } 
        else if(transaction.type === 'sell'){
          return 'Vendre'
        }
        else {
          return transaction.type
        }
    }
    

  return (
        <Paper sx={{
            display: {xs:'flex',sm:'flex'},
            flexDirection: 'row',
            width: '100%',
            padding: 1,
            my:1,
            px:3,
        
            //borderBottom: '1px solid #ccc',
            alignItems: 'center',
            justifyContent:'space-between'
          }}>
            <Box display={'flex'} alignItems={'center'} >
                <Box ml={0}>
                <Box display={'flex'} alignItems={'center'}>
                    <Typography>{wallet.symbol}</Typography>
                    {currentType === 'tous' && <Typography sx={{fontStyle:'italic',color: 'grey',ml:1}}>({type()})</Typography>}
                </Box>
                <Typography sx={{fontStyle:'italic',color:'grey'}}>{convertDayJs(transaction.date)}</Typography>
                </Box>
            </Box>
    
            <Box sx= {{ display:'flex', flexDirection: 'column' , alignItems:'end',mr:3 }}>
              <Typography sx={{
                color: transaction.type === 'buy' || transaction.type === 'depot' ?  'green': '#dc3545',
                fontWeight: 'bold',
              }}>
                {numberFormatter.format(
                        roundNumber( 
                          transaction.type === 'buy' || transaction.type === 'sell' ? 
                            transaction.type === 'buy' ? transaction.amount : -transaction.amount
                            : transaction.type === 'depot' ? transaction.balanceRequested : -transaction.balanceRequested,
                          wallet.round
                  ))
                  }
              </Typography>
              {/* currentBaseWallet !== symbol &&
              <Typography sx={{color:'grey', fontStyle:'italic', fontSize:13}}>
                ≃ 
                {numberFormatter.format(
                        roundNumber( 
                          valueEquivalent,
                          currentBaseWallet === 'MGA' ? 0 : roundTrade
                  ))
                }
                {currentBaseWallet}
              </Typography>
              */}
            </Box>
          </Paper>
  )
}
