import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { backendUrl} from '../utils'
//import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useNavigate } from 'react-router-dom'
import { useWalletContext } from '../context'

export const Depot = () => {

  /*const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets] = results*/


  const {wallets, balances} = useWalletContext()


  return (
    <Container>
      <>
      {( wallets.isLoading
        || wallets.data.data === undefined
        || balances.isLoading
        || balances.data.data === undefined
        ) && <TwoBarContentLoader/>}

      { wallets.isSuccess
        && wallets.data.data !== undefined
        && balances.isSuccess
        && balances.data.data !== undefined
        && 
      <DepotLayout 
        wallets={wallets.data.data} 
        balances={balances.data.data}
        />
      }
      </>
    </Container>
  )
}
const DepotLayout = ({wallets,balances}) => {
  //console.log(wallets)
  return(
    <Stack spacing={1} mt={2}>
      {
      wallets.map((item,index)=>{
        const balance = balances.find(i => i.walletId === item.id)
        return <DepotContainer key={index} id={item.id} img={item.icon} name={item.name} symbol={item.symbol} value={balance.balance}/>
     })
    }
    </Stack>)
}


const DepotContainer = ({id,img,symbol,name,value}) => {
  const navigate = useNavigate()
  return (
    <Paper sx={{
      '&:hover':{
            boxShadow:1,
            backgroundColor:'#00000055',
          },
    }} >
      <Box
        onClick={()=>navigate('/transaction/'+id)}
        sx={{
          display: {xs:'flex',sm:'flex'},
          flexDirection: 'row',
          width: '100%',
          padding: 1,
          my:1,
          px:3,
          cursor:'pointer',
          //borderBottom: '1px solid #ccc',
          alignItems: 'center',
          justifyContent:'space-between'
        }}
      >
        
        <Box display={'flex'} alignItems={'center'} >
            <img src={backendUrl+'image/logo/'+img} width={40} height={40}/>
            <Box ml={3}>
            <Box display={'flex'} alignItems={'center'}>
                <Typography >{symbol}</Typography>
            </Box>
            <Typography sx={{fontStyle:'italic',color:'grey'}}>{name}</Typography>
            </Box>
        </Box>

        <Box sx= {{  display:'flex' }}>
          <Typography mr={3}>
            {value}
          </Typography>
        </Box>
      
      </Box>
    </Paper>
  )
}
