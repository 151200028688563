import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './auth'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


const root = ReactDOM.createRoot(document.getElementById('root'));



const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
          <AuthContextProvider>
            <QueryClientProvider client={queryClient}>  
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </QueryClientProvider>
          </AuthContextProvider>
      </Router>
      <CssBaseline />
    </ThemeProvider>
  </React.StrictMode>
);

