import { Box, CircularProgress, Container, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../utils'
import { PiSealCheckFill } from "react-icons/pi"
import { RxCrossCircled } from "react-icons/rx"
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../auth'

export const EmailVerification = () => {
    const [status,setStatus] = useState('sending')
    const {user,reloadUser} = useAuth()
    const navigate = useNavigate()

    const { token } = useParams()

    console.log(user)
    //const axiosInstance = useAxios()

    const sendComfirm = () => {

        const dataForm = new FormData()
        dataForm.append('token',token)

        setStatus('sending')
        axiosClient.post('/verificationEmail', dataForm)
        .then(resp => {
            //setStatus('success')

            const storedUserData = JSON.parse(localStorage.getItem('userData'))

            if(Object.keys(storedUserData.user).length>0){
                /*setUser((prevState) => ({
                    ...prevState,
                    emailVerificationStatus: true
                }))*/
                alert()
                reloadUser()
            } else{
                alert('ato')
                navigate('/login')
            }
        })
        .catch(e=>{
            console.log(e)
            setStatus('failed')
        })
    }

    useEffect(()=>{
        sendComfirm()
    },[])
  return (
    <Container>
        <Box sx={{display:'flex',justifyContent:'center'}}>
            <Paper sx={{width:{xs:'100%',md:600}, px:{xs:1,md:6}, pb:3, pt:2}}>
                <Stack spacing={1} sx={{}}>

                <Typography align='center' variant='h6' sx={{color:'#0088ff', fontWeight:'bold'}}>VALIDER VOTRE EMAIL !</Typography>
                    
                    { status === 'sending' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <CircularProgress sx={{color: '#fff', fontSize:15}}/>
                            <Box sx={{ml:2}}>
                                <Typography >Comfirmation de l'email...</Typography>
                                <Typography>Veuillez patientez!</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                    { status === 'success' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <PiSealCheckFill color='green' size={50}/>
                            <Box sx={{ml:2}}>
                                <Typography color="danger" >Email envoyer!</Typography>
                                <Typography>Veuillez verifier votre email</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                    { status === 'failed' &&
                        <>
                        <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                            <RxCrossCircled color='red' size={50}/>
                            <Box sx={{ml:2}}>
                                <Typography color="danger">Erreur sur la comfirmation du mail, Veuillez reessayer Plus tard !</Typography>
                                <Typography>Si l'erreur persiste, veuillez nous contacter sur : </Typography>
                                <Typography align='center' sx={{fontWeight:'bold'}}>support@mvxchain.com</Typography>
                            </Box>
                        </Box>
                        </>
                    }
                </Stack>
            </Paper>
        </Box>
    </Container>
  )

}

/**
 UPDATE  email_verification
SET status = false
WHERE id = 1; 
 
 */
