import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { Title } from '../component/admin'
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { axiosClient, backendUrl } from '../utils';
import { useNavigate } from 'react-router-dom';

export const Order = () => {
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['order'],
          queryFn: () => axiosClient.get('order'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,order,paymentMethod] = results
    
    
  return (
    <>
    {(
       order.isLoading 
      || order.data.data === undefined 
      || wallets.isLoading
      || wallets.data.data === undefined
      || paymentMethod.isLoading
      || paymentMethod.data.data === undefined
      ) && <TwoBarContentLoader/>}
  
    {order.isSuccess 
      && order.data.data !== undefined  
      && wallets.isSuccess
      && wallets.data.data !== undefined
      && paymentMethod.isSuccess
      && paymentMethod.data.data !== undefined
      && 
    <OrderComponent
    wallets={wallets.data.data} 
    order={order.data.data}
    paymentMethodData={paymentMethod.data.data}
    />
    }
    </>
  )
}

const OrderComponent = ({order, wallets, paymentMethodData}) => {
    console.log(order)
    const navigate = useNavigate()
return(
    <Container >
        {/* Liste wallet*/}
        <Title title={'LES COMMANDES EN COURS'}/>
        <Grid container mt={3}>
            <Grid item xs={12} md={7}>

            {/* Header */}
            <Box sx={{
              display: {xs:'flex',},//sm:'none'
              flexDirection: 'row',
              width: '100%',
              padding: 1,
              justifyContent:'center'
              //backgroundColor: '#f0f0f0'
            }}>
                <Typography sx= {{ width:'10%'}}></Typography>
                <Typography sx= {{ width:'35%'}}>Solde</Typography>
                <Box sx={{
          display:'flex',
          width:{xs:'30%',md:'20%'},  
        }}>
          <Typography sx={{ml:0}}>Utilisateur</Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
          Portefeuille
        </Box>

        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
             </Box>
            </Box>

            {/* END Header */}  
            
        { 
          order.map((item,index)=>{
            const w = wallets.find(w=>w.id===item.walletId)
            const p= paymentMethodData.find(p=>p.id === item.paymentMethod)
            return (
            <Box key={index}
            onClick={()=>navigate('edit?id='+item.id+'&userId='+item.userId)}
            sx={{cursor:'pointer',}}>
              <Table order={item} wallet={w} pm={p}/>
            </Box>
            )
          })
        }
            </Grid>
        </Grid>
    </Container>
)
}

const Table = ({order,wallet,pm}) => {
    console.log(order)
    return (
    <Paper sx={{
        display: {xs:'flex'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'center',
        '&:hover':{
                boxShadow:1,
                backgroundColor:'#00000055',
              },
      }}>
        <Box sx={{ width:'10%', display:{xs:'none',md:'flex'}}}>
            <Typography sx={{
                backgroundColor: order.type === 'depot' ? '#fd7e14 ': '#6610f2',
                width: 'fit-content',
                px:1,
                borderRadius: 1,
            }}>{order.type}</Typography>
        </Box>
    
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          width:{xs:'50%',md:'30%'},  
        }}>
          <Typography sx={{
            ml:0,
            color:order.type === 'depot' ? 'green': '#dc3545',
            fontWeight:'bold',
            fontSize:20
            }}> 
        {order.type === 'depot' ? '+ ' : '- '}{order.balanceRequested} {wallet.symbol}
            </Typography>
        </Box>
        <Box sx={{
          display:'flex',
          width:{xs:'30%',md:'20%'},  
        }}>
          <Typography sx={{ml:0}}>{order.username}</Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
            <img src={backendUrl+'image/logo/'+wallet.icon} alt={`${wallet.name} icon`} width={30} height={30} />
            <Typography sx={{ml:1, display:{xs:'none',md:'flex'}}}>{wallet.symbol}</Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
            <img src={backendUrl+'image/logo/'+pm.icon} alt={`${pm.name} icon`} width={30} height={30} />
            <Typography sx={{ml:1,display:{xs:'none',md:'flex'}}}>{pm.name}</Typography>
        </Box>

      </Paper>) 
}
//<img src={backendUrl+'image/logo/'+item.icon} alt={`${item.name} icon`} width={30} height={30} />