import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
//import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Grid, Input, InputAdornment, Tab, Tabs, TextField, Typography } from '@mui/material';
import { FaStar } from "react-icons/fa6"
import { CiStar } from "react-icons/ci"
import { useNavigate } from 'react-router-dom';
import { numberFormatter, useAxios } from '../../utils';
import SearchIcon from '@mui/icons-material/Search';
import { useStateContext } from '../../context';
import { priceService } from '../../utils/service';

export const BottomDrawer = ({trade, priceTrade,tradeObj,setCrypto,state, setState}) => {


  const navigate = useNavigate();

  const {price} = useStateContext()

  //console.log('trade',trade)
  const favoriteTrade = trade
  .map(asset => ({
    ...asset,
    compatibleWallet: asset.compatibleWallet.filter(wallet => wallet.favorite === true)
  }))
  .filter(asset => asset.compatibleWallet.length > 0);  // Only keep assets that have non-favorite wallets

  //console.log('non',nonFavoriteWallets);

  const [tabActive,setTabActive] = useState(1)

  const [currentSubTab, setCurrentSubTab] = useState([])
  const [currentTab,setCurrentTab] = useState([])
  const [additionalCurrentSubTab,setAdditionalCurrentSubTab] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [searchedCurrentSub,setSearchedCurrentSub] = useState([])

  const handleChangeTab = (e,newValue) =>{
    setTabActive(newValue)
  }

  useEffect(()=>{

      const filteredF = trade.map(item => {
        // Check if 'u' is found in the main item's name or symbol
        const mainHasU = item.name.toLowerCase().includes(searchInput.toLowerCase()) || item.symbol.toLowerCase().includes(searchInput.toLowerCase());
    
        // If 'u' is found in the main item, keep all compatibleWallets
        if (mainHasU) {
            return item;
        }
    
        // Otherwise, filter the compatibleWallet array based on 'u' in their name or symbol
        const filteredCompatibleWallet = item.compatibleWallet.filter(wallet => 
            wallet.name.toLowerCase().includes(searchInput.toLowerCase()) || wallet.symbol.toLowerCase().includes(searchInput.toLowerCase())
        );
    
        // Return the item with the filtered compatibleWallet
        return { ...item, compatibleWallet: filteredCompatibleWallet };
    }).filter(item => 
        // Only return items that have 'u' in main name/symbol or have non-empty compatibleWallets
        item.compatibleWallet.length > 0 || item.name.toLowerCase().includes(searchInput.toLowerCase()) || item.symbol.toLowerCase().includes(searchInput.toLowerCase())
    );

    if(searchInput !== ""){
      setSearchedCurrentSub(filteredF)
    } else {
      setSearchedCurrentSub([])
    }

    
  // console.log(filteredArray);

    //console.log("ccc",filteredF,'se',searchInput)
  },[searchInput])

  useEffect(()=>{
    const compatibleW = trade.find(i => i.id === tabActive)

    /*const filteredF = trade.filter(item => 
      (item.name.toLowerCase().includes('u') || item.symbol.toLowerCase().includes('u')) || 
      item.compatibleWallet.some(wallet => 
          wallet.name.toLowerCase().includes('u') || 
          wallet.symbol.toLowerCase().includes('u')
      )
    );*/


    const addCompatiblW = trade
    .filter(item => 
      item.id !== tabActive &&
      item.compatibleWallet.some(wallet => wallet.id === tabActive)
    )
    .map(item => ({
      id: item.id,
      name: item.name,
      symbol: item.symbol,
      icon: item.icon,
      compatibleWallet: item.compatibleWallet.find(wallet => wallet.id === tabActive)
    }));

    //console.log("ccc",filteredF)
    if(compatibleW === undefined || addCompatiblW === undefined){
      setCurrentTab([])
      setCurrentSubTab([])
      setAdditionalCurrentSubTab([])
    } else {
      setCurrentTab(compatibleW)
      setCurrentSubTab(compatibleW.compatibleWallet)  
      setAdditionalCurrentSubTab(addCompatiblW)
    } 
  },[tabActive])

  /*useEffect(()=>{
    if(tabActive === 'favorite'){

      
    }
  },[tabActive])*/

  useEffect(() => {

    const handlePopState = (event) => {      
      if (!state) {
        // Navigate back if active is false
        navigate(-1)
      } else {
        // Push the current path to prevent navigation if active is true
        event.preventDefault(); // Optional: Prevent default popstate behavior
        setState(false)
        //navigate(0);
      }

    };

    // Listen for the popstate event
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener('popstate', handlePopState);
    };
  }, [state,navigate]);


  const axiosInstance = useAxios()

  const setFavoriteTrade = (id) => {
    axiosInstance.post('/api/tradeFavorite/'+id)
    .then((resp)=>{tradeObj.refetch()})
    .catch((e)=>{})
  }

  const list = () => (
    <Box
      sx={{ width:'auto', height:{xs:'90vh',md:'80vh'}, pt:0,px:1,pb:1 }}
      role="presentation"
     // onClick={()=>setState(false)}
      //onKeyDown={()=>setState(false)}
    >
      <Box sx={{
        width:'100%',
        backgroundColor:'#1e1e1e',
        position:'fixed',
        zIndex:5
      }}>
        <Box 
        onClick={()=>setState(false)}
        sx={{
          display:'flex',
          width:'100%',
          justifyContent:'center',
          p:2,
        }}>
          <Box sx={{borderRadius:30, width:{xs:130,md:200}, height:13, backgroundColor:'#2b2b2b'}}/>
        </Box>

        <Box sx={{
          width:'100%',
          display:'flex',
          justifyContent:{xs:'center', sm:'start'},
          p:{xs:0,sm:2},
          
        }}>
        <TextField placeholder="Recherche..." size='small' value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}
          InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
            
          }}
        />
        </Box>

      </Box>

      {/* search */}



      <Box sx={{mt:14}}>


      {/* Header */}
      {searchedCurrentSub.length>0 && <Box sx={{display:'flex'}}>
              <Box sx={{width:30}}>

              </Box>

              <Box sx={{
                display: {xs:'flex',},
                flexDirection: 'row',
                width: '100%',
                //padding: 1,
                justifyContent:'center'
                }}>
                    <Typography sx= {{ width:'35%', color:'grey'}}>Nom/Vol</Typography>
                    <Box sx= {{ width:'25%', }}></Box>
                    <Typography sx= {{ width:'40%', color:'grey'}}>Dernier Prix</Typography>
              </Box>

        </Box>}
            {/* END Header */}
      {Object.keys(price).length>0 && searchedCurrentSub.map((item,index)=>{

        return (
          <Box key={index}>
            {item.compatibleWallet.map((value,index)=>{

              const priceT = priceService(item.symbol,value.symbol,price,priceTrade)
              return(
                <Box key={index} 
                  onClick={()=>{
                    setCrypto(value.tradeId)
                    setState(false)
                  }}
                  sx={{
                    display:'flex', 
                    py:0.5,
                    my:0.5,
                  // backgroundColor: 'red',
                    borderRadius:1,
                    '&:hover': {
                      backgroundColor:'#ffffff11',
                      cursor:'pointer',
                    }
                  }}>
                    <Box 
                    onClick={()=>setFavoriteTrade(value.tradeId)}
                    sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                      {value.favorite ? <FaStar color='yellow' size={20}/> : <CiStar size={20}/>}
                    </Box>
                    <Box  
                      sx={{
                        display: {xs:'flex'}, //,sm:'none'
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent:'center'
                    }}>
                        <Box sx={{
                          display:'flex',
                          alignItems:'baseline',
                          width:'35%',  
                          }}>
                        <Typography sx={{}}>{value.symbol}</Typography>
                        <Typography sx={{color:'grey', fontSize:10}}>/{item.symbol}</Typography>
                        </Box>

                        <Box sx= {{ width:'25%'}}>

                        </Box>
                        
                        <Box sx= {{ width:'40%', display:'flex'}}>
                        {numberFormatter.format(priceT.lastPrice)}
                        </Box>
                    </Box>
                  </Box>
              )
            })}
          </Box>
        )
        })}

        {/* empty  */}
        {searchedCurrentSub.length === 0 && searchInput !== '' && <Typography sx={{color:'grey', fontStyle:'italic'}}>Rien ne correspond</Typography>
        }
        
        </Box>

        

      {/* end search */}

      { searchInput === '' &&
      <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',mt:12 }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab value={'favoris'} label="Favoris" />
          {trade.map((item,index)=><Tab key={index} value={item.id} label={item.symbol}/>)}
        </Tabs>
      </Box>
      <Grid container >
        <Grid item xs={12} md={6} sx={{mt:1, overflow:'auto'}}>
           {/* Header */}
           <Box sx={{display:'flex'}}>
              <Box sx={{width:30}}>

              </Box>

              <Box sx={{
                display: {xs:'flex',},
                flexDirection: 'row',
                width: '100%',
                //padding: 1,
                justifyContent:'center'
                }}>
                    <Typography sx= {{ width:'35%', color:'grey'}}>Nom/Vol</Typography>
                    <Box sx= {{ width:'25%', }}></Box>
                    <Typography sx= {{ width:'40%', color:'grey'}}>Dernier Prix</Typography>
              </Box>

            </Box>
            {/* END Header */}

            {/* start  list additional sub */}
            {Object.keys(price).length>0 && additionalCurrentSubTab.map((item,index)=>{
              //console.log(currentTab.symbol)
               const priceT = priceService(item.symbol,currentTab.symbol,price,priceTrade)
                return (
                <Box key={index} 
                  onClick={()=>{
                    setCrypto(item.compatibleWallet.tradeId)
                    setState(false)
                  }}
                  sx={{
                    display:'flex', 
                    py:0.5,
                    my:0.5,
                  // backgroundColor: 'red',
                    borderRadius:1,
                    '&:hover': {
                      backgroundColor:'#ffffff11',
                      cursor:'pointer',
                    }
                  }}>
                    <Box 
                    onClick={()=>setFavoriteTrade(item.compatibleWallet.tradeId)}
                    sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                      {item.compatibleWallet.favorite ? <FaStar color='yellow' size={20}/> : <CiStar size={20}/>}
                    </Box>
                    <Box  
                      sx={{
                        display: {xs:'flex'}, //,sm:'none'
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent:'center'
                    }}>
                        <Box sx={{
                          display:'flex',
                          alignItems:'baseline',
                          width:'35%',  
                          }}>
                        <Typography sx={{}}>{currentTab.symbol}</Typography>
                        <Typography sx={{color:'grey', fontSize:10}}>/{item.symbol}</Typography>
                        </Box>

                        <Box sx= {{ width:'25%'}}>

                        </Box>
                        
                        <Box sx= {{ width:'40%', display:'flex'}}>
                        {numberFormatter.format(priceT.lastPrice)}
                        </Box>
                    </Box>
                  </Box>)
                })}

            {/* end  list additional sub */}

            {/* start  list */}
            {Object.keys(price).length>0 && currentSubTab.map((item,index)=>{
              //console.log(currentTab.symbol)
              const priceT = priceService(currentTab.symbol,item.symbol,price,priceTrade)
              //console.log(priceT.lastPrice)
              return (
              <Box key={index} 
                onClick={()=>{
                  setCrypto(item.tradeId)
                  setState(false)
                }}
                sx={{
                  display:'flex', 
                  py:0.5,
                  my:0.5,
                // backgroundColor: 'red',
                  borderRadius:1,
                  '&:hover': {
                    backgroundColor:'#ffffff11',
                    cursor:'pointer',
                  }
                }}>
                  <Box 
                  onClick={()=>setFavoriteTrade(item.tradeId)}
                  sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {item.favorite ? <FaStar color='yellow' size={20}/> : <CiStar size={20}/>}
                  </Box>
                  <Box  
                    sx={{
                      display: {xs:'flex'}, //,sm:'none'
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent:'center'
                  }}>
                      <Box sx={{
                        display:'flex',
                        alignItems:'baseline',
                        width:'35%',  
                        }}>
                      <Typography sx={{}}>{item.symbol}</Typography>
                      <Typography sx={{color:'grey', fontSize:10}}>/{currentTab.symbol}</Typography>
                      </Box>

                      <Box sx= {{ width:'25%'}}>

                      </Box>
                      
                      <Box sx= {{ width:'40%', display:'flex'}}>
                      {numberFormatter.format(priceT.lastPrice)}
                      </Box>
                  </Box>
                </Box>)
              })}
            
            
          {/* END start  list  */}

          {/* tab favorite */}
          {tabActive === 'favoris' && favoriteTrade.map((i,index)=>{
              
              return (
                <Box key={index}>
                { Object.keys(price).length>0 &&
                i.compatibleWallet.map((item,index)=>{
                  //console.log("item",i)
                  const priceT = priceService(i.symbol,item.symbol,price,priceTrade)
                  return (
                  <Box key={index} 
                    onClick={()=>{
                      setCrypto(item.tradeId)
                      setState(false)
                    }}
                    sx={{
                      display:'flex', 
                      py:0.5,
                      my:0.5,
                    // backgroundColor: 'red',
                      borderRadius:1,
                      '&:hover': {
                        backgroundColor:'#ffffff11',
                        cursor:'pointer',
                      }
                    }}>
                      <Box sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <FaStar color='yellow' size={20}/>
                      </Box>
                      <Box  
                        sx={{
                          display: {xs:'flex'}, //,sm:'none'
                          flexDirection: 'row',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent:'center'
                      }}>
                          <Box sx={{
                            display:'flex',
                            alignItems:'baseline',
                            width:'35%',  
                            }}>
                          <Typography sx={{}}>{item.symbol}</Typography>
                          <Typography sx={{color:'grey', fontSize:10}}>/{i.symbol}</Typography>
                          </Box>

                          <Box sx= {{ width:'25%'}}>

                          </Box>
                          
                          <Box sx= {{ width:'40%', display:'flex'}}>
                          {numberFormatter.format(priceT.lastPrice)}
                          </Box>
                      </Box>
                    </Box>)
                })
                }
                </Box>
            )}
          )}
              
            
          {/* END tab favorite */}


            {/* start  list */}
          {/*  <Box sx={{
              display:'flex', 
              py:1
            }}>
              <Box sx={{width:30, display:'flex', alignItems:'center'}}>
              <CiStar size={20}/>
              </Box>
              <Box  
                sx={{
                  display: {xs:'flex'}, //,sm:'none'
                  flexDirection: 'row',
                  width: '100%',
                  //padding: 1,
                  //my:1,    
                  alignItems: 'center',
                  justifyContent:'center'
              }}>
                  <Box sx={{
                    display:'flex',
                    width:'35%',  
                    }}>
                  <Typography sx={{}}>item.name</Typography>
                  </Box>

                  <Box sx= {{ width:'25%'}}>

                  </Box>
                  
                  <Box sx= {{ width:'40%', display:'flex'}}>
                    5069
                  </Box>
              </Box>
            </Box>*/}
            
        {/* END start  list  */}

        </Grid>
      </Grid>
     {/* <FaStar />
      <CiStar size={20}/> */}
    </>
    }
    </Box>
  );

  return (
    <div>
          <SwipeableDrawer
            anchor={"bottom"}
            elevation={1}
            open={state}
            onClose={()=>setState(false)}
            onOpen={()=>setState(true)}
            sx={{pt:0}}
          >
            {list()}
          </SwipeableDrawer>
    </div>
  );
}
