
import { Route, Routes } from 'react-router-dom';
import './style/style.css'
import { AppOutlet, Contation, Home, LoginPage, Trade, User, Wallet , Extra, Depot, Transaction, Retrait, DepotTransaction, RetraitTransaction, EmailVerification, EmailVerificationNonUser, TransactionComfirmation, WalletDetail} from "./pages";
import { Register, RequireAuth, RequireAuthAdmin, RequireAuthEmail } from './auth';
import { Category, Dashboard, WalletAdmin, PaymentMethod, NewWallet, EditWallet, NewPaymentMethod, EditPaymentMethod, NewCategory, EditCategory, TradeAdmin, NewTrade, EditTrade, RateTrade, Order, EditOrder, TradeOrder } from './scenes';
import { ResendMailVerification } from './pages/ResendMailVerification';



function App() {
  return (
    <div className="App">
    <Routes>   
      {/* public route */}
      <Route path={'/login'} element={<AppOutlet/>}>
        <Route index element={<LoginPage/>}/>
      </Route>
      <Route path='/register' element={<Register/>}/>

      <Route path='/emailVerification/:token' element={<EmailVerification/>}/>
      <Route path='/emailVerifications/:token' element={<EmailVerificationNonUser/>}/>

      {/* restricted route */}
      <Route path='/emailVerification' element={<RequireAuthEmail/>}>
        <Route index element={<ResendMailVerification/>}/>
        {/*<Route path=':token' element={<EmailVerification/>}/>*/}
      </Route>
      
      {/* protected route */}
      <Route path={'/'} element={<RequireAuth/>}>
        <Route index element={<Home/>}/>
        <Route path='cotation' element={<Contation/>} />
        <Route path='trade' element={<Trade/>} />
        <Route path='user' element={<User/>} />
        <Route path='wallet' element={<Wallet/>}/>
        <Route path='wallet/detail/:id' element={<WalletDetail/>}/>
        <Route path='transaction' element={<Transaction/>}>
          <Route index element={<Depot/>}/>
          <Route path='retrait' element={<Retrait/>}/>
          <Route path='/transaction/:id' element={<DepotTransaction/>}/>
          <Route path='/transaction/retrait/:id' element={<RetraitTransaction/>}/>
        </Route>

        <Route path='/transac/comfirmation/:transactionId' element={<TransactionComfirmation/>}/>
        <Route path='depot' element={<Depot/>}/>
        <Route path='extra' element={<Extra/>}/>
      </Route>

      {/* protected route admin */}

      <Route path={'/admin'} element={<RequireAuthAdmin/>}>
        <Route index element={<Dashboard/>}/>
        <Route path='wallet' element={<WalletAdmin/>}/>
        <Route path='wallet/new' element={<NewWallet/>}/>
        <Route path='wallet/edit/:id' element={<EditWallet/>}/>
        <Route path='category' element={<Category/>}/>
        <Route path='category/new' element={<NewCategory/>}/>
        <Route path='category/edit/:id' element={<EditCategory/>}/>
        <Route path='paymentMethod' element={<PaymentMethod/>}/>
        <Route path='paymentMethod/new' element={<NewPaymentMethod/>}/>
        <Route path='paymentMethod/edit/:id' element={<EditPaymentMethod/>}/>
        <Route path='trade' element={<TradeAdmin/>}/>
        <Route path='trade/new' element={<NewTrade/>}/>
        <Route path='trade/edit/:id' element={<EditTrade/>}/>
        <Route path='trade/rate' element={<RateTrade/>}/>
        <Route path='order' element={<Order/>}/>
        <Route path='order/trade' element={<TradeOrder/>}/>
        <Route path='order/edit' element={<EditOrder/>}/>
      </Route>
    </Routes>
    </div>
  );
}

export default App;
