import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { backendUrl, numberFormatter, roundNumber } from '../utils'
import { useStateContext } from '../context'
import { priceService } from '../utils/service'

export const WalletShow = ({img,symbol,name,value,priceTrade,round,roundTrade,currentBaseWallet}) => {

  const {price} = useStateContext()

  const [valueEquivalent,setValueEquivalent] = useState(0)


  useEffect(() => {
    if( Object.keys(price).length>0){
      if(symbol !== 'MGA'){
        //console.log('bal',balance)
        //console.log(item)
        setValueEquivalent(priceService(currentBaseWallet,symbol,price,priceTrade).lastPrice*value)

      } else {
        if(currentBaseWallet==='MGA'){
          //setValueEquivalent()
        } else {
          const priceTr = priceTrade.find(p=>p.pairSymbol===currentBaseWallet)
          const lastPrice = (priceTr.sellPrice+priceTr.buyPrice)/2            
          setValueEquivalent(value/lastPrice)
        }
      }
    }
  },[currentBaseWallet,price])


  return (
    
    <Paper sx={{
        display: {xs:'flex',sm:'flex'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
        px:3,
    
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'space-between',
        '&:hover':{
                boxShadow:1,
                backgroundColor:'#00000055',
                cursor:'pointer'
        },

      }}>
        <Box display={'flex'} alignItems={'center'} >
          <img src={backendUrl+'image/logo/'+img} alt={name} width={40} height={40} />
            <Box ml={3}>
            <Box display={'flex'} alignItems={'center'}>
                <Typography >{symbol}</Typography>
            </Box>
            <Typography sx={{fontStyle:'italic',color:'grey'}}>{name}</Typography>
            </Box>
        </Box>

        <Box sx= {{ display:'flex', flexDirection: 'column' , alignItems:'end',mr:3 }}>
          <Typography >
            {numberFormatter.format(
                    roundNumber( 
                      value,
                      currentBaseWallet.symbol === 'MGA' ? 0 : round
              ))
              }
          </Typography>
          { currentBaseWallet !== symbol &&
          <Typography sx={{color:'grey', fontStyle:'italic', fontSize:13}}>
            ≃ 
            {numberFormatter.format(
                    roundNumber( 
                      valueEquivalent,
                      currentBaseWallet === 'MGA' ? 0 : roundTrade
              ))
            }
            {currentBaseWallet}
          </Typography>
          }
        </Box>
      </Paper>
  )
}
