import { Box, Button, CircularProgress, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { backendUrl } from '../utils'
import { FormInput } from '../component/form'
import { ModalError } from '../component'
import { PiSealCheckFill } from "react-icons/pi"


export const Register = () => {

  const ENDPOINT = backendUrl+'register'

  const [isLoading, setIsLoading]= useState(false)
  const [error,setError] = useState(false)
  const [errormessage, setErrorMessage]= useState('')
  const [success , setSucess] = useState(false)

  

    const dataForm = new FormData()
    const navigate = useNavigate()
    // for validating the check box
    const validationSchema = Yup.object().shape({
      email : Yup.string()
        .required('ce champ ne peut pas etre vide')
        .matches(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'veuillez entrer un addresse mail valide'),
        username: Yup.string()
        .required("Veillez ajouter un nom d'utilisateur"),
        password: Yup.string()
        .required('Veillez ajouter un mot de passe')
        .min(6, 'Mot doit etre au minimum 6 caractere'),
      passwordComfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Mot de passe doit etre le meme '),
    })

    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            email: '',
            username: '',
            password: '',
            passwordComfirmation: '',
          },
      })

      
    const {handleSubmit,formState: { errors }} = methods

      const onSubmit = (data) => {
        setIsLoading(true)
        console.log(data)

      dataForm.append('email',data.email)
      dataForm.append('username',data.username)
      dataForm.append('password',data.password)
      
      axios.post(ENDPOINT,dataForm,
      {
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
      .then(resp => {
        setIsLoading(false)
        console.log("mety",resp.data)
        setSucess(true)
        //navigate('/login')
    })
    .catch(error => {
      console.log(error)
      setError(true)
      setIsLoading(false)
      setErrorMessage(error.response.data.message)
      console.log(error.response.data.message)
    })
      }
    const test = () => {
      axios.get(backendUrl+'home')
      .then((resp)=>{console.log('nety', resp.data)})
      .catch((e)=>{console.log('tsy mety',e)})
    }

  return (
    <Container>
        <ModalError open={error} handleClose={()=>setError(false)} message={errormessage}/>
        <Grid container sx={{mt:3}}>

            <Grid item xs={12} md={6} >
              <Button variant='contained' onClick={test}>
                test
              </Button>

            </Grid>

            <Grid item xs={12} md={6} >
            <Paper elevation={1} sx={{
                    width:{xs:'95%', md:'80%'},
                    height:'fit-content',
                    p:{xs:2,sm:4,md:6},
                    boxShadow:6,
                    backgroundColor:'#1e1e1ebb'
            }}>
                { ! success && 
                  <Stack spacing={1}>
                    <Typography variant='h6'>Inscription</Typography>
                    <FormProvider {...methods}>
                    <form>
                        <Stack spacing={1}>
                            <FormInput name='email' label='Email'  error={!!errors?.email} helperText={errors?.email?.message} fullWidth/>
                            <FormInput name='username' label="Nom d'utilisateur" error={!!errors?.username} helperText={errors?.username?.message} fullWidth/>
                            <FormInput name='password' type='password' label="Mot de passe" error={!!errors?.password} helperText={errors?.password?.message} fullWidth/>
                            <FormInput name='passwordComfirmation' type='password' label='Comfirmer Mot de passe' error={!!errors?.passwordComfirmation} helperText={errors?.passwordComfirmation?.message} fullWidth/>
                            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                                
                            { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Creer un compte"}
                            </Button>
                        </Stack>
                    </form>
                    </FormProvider>
                </Stack>
              }
              { success &&
              <Box sx={{display:'flex',width:'100%', justifyContent:'center', alignItems:'center'}}>
                  <PiSealCheckFill color='green' size={50}/>
                  <Box sx={{ml:2}}>
                      <Typography color="danger" >Un email de confirmation a été envoyé!</Typography>
                      <Typography>Veuillez verifier votre email</Typography>
                  </Box>
              </Box>

              }
            </Paper>
            </Grid>

        </Grid>
    </Container>
  )
}
