import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation} from 'react-router-dom'
import { useAuth } from './useAuth'
import { ButtonNavigation, Header } from '../component'
import { Box, Button } from '@mui/material'
import { StateContextProvider } from '../context'
import backgroundImg from '../asset/image/Manavola.webp'

export const RequireAuthEmail = () => {

    const auth = useAuth()
    const location = useLocation()

    const [toLogin,setToLogin] = useState(false)
    const [toHome,setToHome] = useState(false)

    useEffect(()=>{
        if(auth.isAuthenticated !== null && auth.isAuthenticated === false){
          setToLogin(true)
        }
        if(auth.isEmailVerified !== null && auth.isEmailVerified === true){
          setToHome(true)
        }
    },[auth])

  return (
    <>
    <Button onClick={auth.handleLogout}>Logout</Button>
    { auth.isAuthenticated && ! auth.isEmailVerified &&
            <>
                <Box sx={{
                  pt:{xs:2,md:9},
                  pb:{xs:9,md:3},
                  position:'relative'
                }}>
                  <Box component={'img'} src={backgroundImg} width={'65%'} sx={{position:'absolute', display:{xs:'flex',md:'none'}, zIndex:-5,right:0,top:40, opacity:'2%'}}/>
                  <Outlet/>
                </Box>
            </>
    }
    { toLogin &&
      <Navigate to='/login' state={{from: location}} replace /> 
    }
    { toHome &&
      <Navigate to='/' state={{from: location}} replace /> 
    }
    </>
  )
}

///<Navigate to='/login' state={{from: location}} replace /> 