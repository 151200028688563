import { Box, Button, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const SimpleMenu = ({open,setOpen,state,setState,options,sx}) => {
    //const {open,setOpen,setState,options} = props


  const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
      };
    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }
  return (
    <Box>
    <Button sx={sx} endIcon={<ArrowDropDownIcon />} onClick={handleClick}>{state}</Button>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((item)=>{
            return(
            <MenuItem key={item} onClick={()=>{
                setState(item)
                handleClose()
            }}>{item}</MenuItem>   
            )
        })}
      </Menu>
    </Box>
  )
}
