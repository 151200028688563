import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const MuiSelect = React.forwardRef((props, ref) => {
    const {label,name, options}= props

    return (
        <FormControl {...props}>
          <InputLabel id={name}>{label}</InputLabel>
            <Select 
              labelId={name} 
              label={label} 
              ref={ref} // Forward the ref here
              {...props}>
                {options.map((item,index)=>(
                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                ))}
                {props.others}
            </Select>
          </FormControl>
    )
})

export const FormSelect = (props) => {


    const {control} = useFormContext()
    const {name,label} = props
  return (
    <Controller
    render={({field})=>(
        <MuiSelect {...props} {...field}/>
    )}
    control={control}
    name={name}/>
  )
}