import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useRef } from 'react'
import Countdown from 'react-countdown'
import { LuClock2 } from "react-icons/lu"

export const CountdownComponent = ({transactionId, onComplete}) => {
  const countdownRef = useRef(null)

  let time

  const storedTime = JSON.parse(localStorage.getItem('time'+transactionId))

  if(storedTime === null){
    time=dayjs(Date.now() + 900000).format('YYYY-MM-DD HH:mm:ss')
    localStorage.setItem(
      'time'+transactionId , 
      JSON.stringify(
        {time}
      ))
  }
  else {
    time = storedTime.time
  }

  console.log('time',time)


	return(
		<Countdown ref={countdownRef} date={time} 
          onComplete={onComplete}
         renderer={renderer}/>)
}
const renderer = ({ days,hours, minutes, seconds, completed }) => {
 return(
  <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
  <Box sx={{
    border:'1px solid white',
    borderRadius:2,
    width:'fit-content',
    p:1,
    mb:1,
    display:'flex',
    alignItems:'center',
}}>
    <LuClock2 size={30}/>
    <Box sx={{ml:1, display: 'flex', alignItems:'center'}}>
        <TimeShow value={minutes}/>
        <Box>:</Box>
        <TimeShow value={seconds}/>
    </Box>
</Box>
<Typography>Vous avez {minutes+1} minute au plus tard pour envoyer l’argent</Typography>
</Box>
 )
}

const TimeShow = ({label,value}) => {
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems:'center',
			width: 'fit-content',
		}}>
			<Typography sx={{
				//color: colorFront,
				fontSize: 30,
				//p:1
			}}>
				{value>9 ? value : '0'+value}
			</Typography>

			<Typography sx={{
				//color: colorFront,
				fontSize: 12,
				position: 'relative',
				//top:-16,
			}}>
				{label}
			</Typography>
		</Box>
	)
}