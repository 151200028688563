import { useQueries } from '@tanstack/react-query';
import React from 'react'
import { TwoBarContentLoader } from '../component/contentLoading';
import { axiosClient, backendUrl, useAxios } from '../utils';
import { Box, Paper, Typography } from '@mui/material';

export const TradeOrder = () => {

  const axiosInstance = useAxios()

    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['tradeOrder'],
          queryFn: () => axiosClient.get('order/trade'),
        },
        {
            queryKey: ['trade'],
            queryFn: () =>   axiosInstance.get('/trade'),
          },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,tradeOrder,trade] = results
    
  return (
    <>
    {(
       tradeOrder.isLoading 
      || tradeOrder.data.data === undefined 
      || wallets.isLoading
      || wallets.data.data === undefined
      || trade.isLoading
      || trade.data.data === undefined
      ) && <TwoBarContentLoader/>}
  
    {tradeOrder.isSuccess 
      && tradeOrder.data.data !== undefined  
      && wallets.isSuccess
      && wallets.data.data !== undefined
      && trade.isSuccess
      && trade.data.data !== undefined
      && 
        <TradeOrderComponent
        wallets={wallets.data.data} 
        tradeOrder={tradeOrder.data.data}
        trade={trade.data.data}
        />
    }
    </>
  )
}

const TradeOrderComponent = ({tradeOrder,trade}) => {
    console.log(tradeOrder)

    return (
    <>
    {
        tradeOrder.map((item,index)=>{
            const t = trade.map((i) => {
    
                const wallet = i.compatibleWallet.find((wallet) => wallet.tradeId == item.baseTradeId);
                if (wallet) {
                  return {
                    id: i.id,
                    name: i.name,
                    symbol: i.symbol,
                    icon: i.icon,
                    round:i.round,
                    compatibleWallet: wallet,
                  };
                }
                return null;
              })
            .find((item) => item !== null);

            console.log('t',item)

            return(<>
                <Table trade={t} tOrder={item}/>
                </>
            )
        })
    }
    </>
    )

}

const Table = ({trade,tOrder}) => {
    //console.log(order)
    return (
    <Paper sx={{
        display: {xs:'flex'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'center',
        '&:hover':{
                boxShadow:1,
                backgroundColor:'#00000055',
              },
      }}>
        
    
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          width:{xs:'40%',md:'40%'},  
        }}>
          <Typography sx={{
            ml:0,
            color:tOrder.type === 'buy' ? 'green': '#dc3545',
            fontWeight:'bold',
            fontSize:20
            }}> 
        {tOrder.type === 'buy' ? '+ ' : '- '}{tOrder.amount} {trade.compatibleWallet.symbol}
            </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'30%',md:'30%'}}}>
            <Typography sx={{}}>{trade.compatibleWallet.symbol}/{trade.symbol}</Typography>
        </Box>
        <Box sx={{
          display:'flex',
          width:{xs:'30%',md:'30%'},  
        }}>
          <Typography sx={{ml:0}}>{tOrder.username}</Typography>
        </Box>
{/* 
        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
            <img src={backendUrl+'image/logo/'+trade.icon} alt={`${trade.name} icon`} width={30} height={30} />
            <Typography sx={{ml:1, display:{xs:'none',md:'flex'}}}>{trade.symbol}</Typography>
        </Box>
*/}
        <Box display={'flex'} alignItems={'center'} sx= {{ width:{xs:'10%',md:'20%'}}}>
        </Box>

      </Paper>) 
}
