import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { axiosClient, backendUrl } from '../utils'
import { useQueries } from '@tanstack/react-query'
import { Avatar, Box, Button, Chip, CircularProgress, Container, Divider, Grid, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { TwoBarContentLoader } from '../component/contentLoading'
import { Title } from '../component/admin'
import { MdEdit } from "react-icons/md"
import axios from 'axios'
import CancelIcon from "@mui/icons-material/Cancel"
import { SnackBarStatus } from '../component'


export const EditWallet = () => {

    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,categories,paymentMethod] = results
    
  return (
    <Container>
    {(
      categories.isLoading 
      || categories.data.data === undefined 
      || wallets.isLoading
      || wallets.data.data === undefined
      || paymentMethod.isLoading
      || paymentMethod.data.data === undefined
      ) && <TwoBarContentLoader/>}

    {categories.isSuccess 
      && categories.data.data !== undefined  
      && wallets.isSuccess
      && wallets.data.data !== undefined
      && paymentMethod.isSuccess
      && paymentMethod.data.data !== undefined
      && 
    <EditWalletLayout 
      walletObject={wallets}
      wallets={wallets.data.data} 
      categories={categories.data.data}
      paymentMethodData={paymentMethod.data.data}
      />
    }
  
    </Container>
  )
}

const EditWalletLayout = ({categories, wallets,walletObject, paymentMethodData,allPaymentMethodInCategory}) => {
  //console.log(wallets[0].paymentMethod)
  const { id } = useParams()
  //const [wallet,setWallet] = useState(wallets.find(w => w.id === id))
  const wallet = wallets.find(w => w.id == id)
  const category = categories.find(c => c.id == wallet.categoryId)
  const filteredPm = paymentMethodData.filter(payment => wallet.paymentMethod.includes(payment.id));
  //const tempPm = allPaymentMethodInCategory.find(item => item.categoryId === wallet.categoryId);
  //console.log(tempPm)
  //console.log(category)

  //serie ana useSatet edit
  const [isNameEdited,setNameEdited] = useState(false)
  const [isNameEditedLoading,setNameEditedLoading] = useState(false)
  const [isSymbolEdited,setSymbolEdited] = useState(false)
  const [isIconEdited,setIconEdited] = useState(false)
  const [isCategoryEdited,setCategoryEdited] = useState(false)
  const [isPaymentMethodEdited,setPaymentMethodEdited] = useState(false)

  const [isSymbolEditedLoading,setSymbolEditedLoading] = useState(false)
  const [isIconEditedLoading,setIconEditedLoading] = useState(false)
  const [isCategoryEditedLoading,setCategoryEditedLoading] = useState(false)
  const [isPaymentMethodEditedLoading,setPaymentMethodEditedLoading] = useState(false)


  const [nameEditedValue,setNameEditedValue] = useState(wallet.name)
  const [symbolEditedValue,setSymbolEditedValue] = useState(wallet.symbol)
  const [iconEditedValue,setIconEditedValue] = useState('')
  const [categoryEditedValue,setCategoryEditedValue] = useState(wallet.categoryId)
  const [paymentMethodEditedValue,setPaymentMethodEditedValue] = useState(wallet.paymentMethod)


  const [isRoundEdited,setRoundEdited] = useState(false)
  const [isRoundEditedLoading,setRoundEditedLoading] = useState(false)
  const [roundEditedValue,setRoundEditedValue] = useState(wallet.round)

  const [isRoundTradeEdited,setRoundTradeEdited] = useState(false)
  const [isRoundTradeEditedLoading,setRoundTradeEditedLoading] = useState(false)
  const [roundTradeEditedValue,setRoundTradeEditedValue] = useState(wallet.roundTrade)


  //snacbar

  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  const sendChange = (identifier,value,setEdited,setEditLoading) => {
    const dataForm = new FormData()
    dataForm.append('identifier',identifier)

    if(identifier==='paymentMethod'){
      value.forEach((element,index) => {
        dataForm.append(`value[${index}]`,element)
      });
    }
    else {
      dataForm.append('value',value)
    }

    axios.post(backendUrl+'wallet/'+id,dataForm,
      {
        headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:`Modification du ${identifier} avec success`, severity: 'success'})
      setEdited(false)
      setEditLoading(false)
      setStatus(true)
      walletObject.refetch()
    })
    .catch(e=>{
      setSnackbar({children:`Erreur sur la modification du ${identifier}`, severity: 'error'})
      setEditLoading(false)
      setStatus(true)
      console.log(e)
    })
  }

  const handleChangeWallet = (identifier) => {
    console.log(identifier)
    if (identifier === 'name'){
      setNameEditedLoading(true)
      sendChange(identifier,nameEditedValue,setNameEdited,setNameEditedLoading)
    }
    if (identifier === 'symbol'){
      setSymbolEditedLoading(true)
      sendChange(identifier,symbolEditedValue,setSymbolEdited,setSymbolEditedLoading)
    }

    if (identifier === 'icon'){
      setIconEditedLoading(true)
      sendChange(identifier,iconEditedValue,setIconEdited,setIconEditedLoading)
    }

    if (identifier === 'category'){
      setCategoryEditedLoading(true)
      sendChange(identifier,categoryEditedValue,setCategoryEdited,setCategoryEditedLoading)
    }

    if (identifier === 'paymentMethod'){
      setPaymentMethodEditedLoading(true)
      sendChange(identifier,paymentMethodEditedValue,setPaymentMethodEdited,setPaymentMethodEditedLoading)
    }

    if (identifier === 'round'){
      setRoundEditedLoading(true)
      sendChange(identifier,roundEditedValue,setRoundEdited,setRoundEditedLoading)
    }

    if (identifier === 'roundTrade'){
      setRoundTradeEditedLoading(true)
      sendChange(identifier,roundTradeEditedValue,setRoundTradeEdited,setRoundTradeEditedLoading)
    }

  }


  //image
  const [imagePreview, setImagePreview] = useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setIconEditedValue(file)
      };
      reader.readAsDataURL(file);
    }
  }

  return(
    <>
    <Title title={"L'ACTIF "+wallet.symbol}/>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          p:{xs:1,md:3}
        }}>
          <Stack spacing={1} sx={{px:2}}>
            {/* Name */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isNameEdited ?'white':'grey'}}>Nom</Typography>
                {!isNameEdited && <Typography>{wallet.name}</Typography>}
              </Box>
                {!isNameEdited && <IconButton onClick={()=>setNameEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isNameEdited &&
            <>
            <TextField value={nameEditedValue} onChange={(e)=> setNameEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNameEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('name')} variant='contained' sx={{width:100}}>{isNameEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Name */}

            {/* Symbol */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isSymbolEdited ?'white':'grey'}}>Symbole</Typography>
                {!isSymbolEdited && <Typography>{wallet.symbol}</Typography>}
              </Box>
                {!isSymbolEdited && <IconButton onClick={()=>setSymbolEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isSymbolEdited &&
            <>
            <TextField value={symbolEditedValue} onChange={(e)=> setSymbolEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNameEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('symbol')} variant='contained' sx={{width:100}}>{isSymbolEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Symbol */}

            {/* icon */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isIconEdited ?'white':'grey'}}>Logo</Typography>
                {!isIconEdited && <img src={backendUrl+'image/logo/'+wallet.icon} alt={wallet.name+' icon'} width={40} height={40}/>}
              </Box>
                {!isIconEdited && <IconButton onClick={()=>setIconEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isIconEdited &&
            <>
            <Box sx={{
              display:'flex',
              alignItems:'center',
              mr:2
            }}>
              <Box>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => handleImageChange(e)}
                style={{ display: 'none' }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Logo
                </Button>
              </label>
              </Box>
              {imagePreview && (
                <Box ml={2}>
                  <Avatar
                    alt="Image Preview"
                    src={imagePreview}
                    sx={{ width: 30, height: 30 }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setIconEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('icon')} variant='contained' sx={{width:100}}>{isIconEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Icon */}

            {/* Category */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isCategoryEdited ?'white':'grey'}}>Category</Typography>
                {!isCategoryEdited && <Typography>{category.name}</Typography>}
              </Box>
                {!isCategoryEdited && <IconButton onClick={()=>setCategoryEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isCategoryEdited &&
            <>
            <Select  value={categoryEditedValue} onChange={(e)=> setCategoryEditedValue(e.target.value)}fullWidth sx={{my:1}}>
            {categories.map((item,index)=><MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
            </Select>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setCategoryEdited(false)}  sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('category')} variant='contained' sx={{width:100}}>{isCategoryEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Category */}

            {/* PaymentMethod */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isPaymentMethodEdited ?'white':'grey'}}>Methode de Paiement</Typography>
                {!isPaymentMethodEdited && 
                  <Box sx={{display:'flex', flexWrap:'wrap'}}>
                    {filteredPm.map((item,index)=>
                    <Box key={index} m={0.5}>
                      <img src={backendUrl+'image/logo/'+item.icon} alt={item.name} width={30} height={30} />
                    </Box>)
                    }
                  </Box>
                }
              </Box>
                {!isPaymentMethodEdited && <IconButton onClick={()=>setPaymentMethodEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isPaymentMethodEdited &&
            <>
            <Select 
              value={paymentMethodEditedValue} 
              multiple 
              onChange={(e)=> setPaymentMethodEditedValue(e.target.value)}fullWidth sx={{my:1}}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value, index) => {
                    const tempValue = paymentMethodData.find(item => item.id === value);
                    return (
                      <Chip
                        key={index}
                        label={tempValue.name}
                        avatar={<img src={`${backendUrl}image/logo/${tempValue.icon}`} alt={`${tempValue.name} icon`} width={30} height={30} />}
                        onDelete={() => {
                          setPaymentMethodEditedValue(selected.filter((item) => item !== value))
                        }}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              )}>
            {paymentMethodData.map((item,index)=>
              <MenuItem key={index} value={item.id}>
                <Box sx={{display:'flex'}}>
                  <img src={`${backendUrl}image/logo/${item.icon}`} alt={`${item.name} icon`} width={30} height={30} />
                  <Typography ml={1}>{item.name}</Typography>
                </Box>
              </MenuItem>)}
            </Select>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setPaymentMethodEdited(false)}  sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('paymentMethod')} variant='contained' sx={{width:100}}>{isPaymentMethodEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            </Box>
            {/* END PaymentMethod */}

            {/* Round */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isRoundEdited ?'white':'grey'}}>Round</Typography>
                {!isRoundEdited && <Typography>{wallet.round}</Typography>}
              </Box>
                {!isRoundEdited && <IconButton onClick={()=>setRoundEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isRoundEdited &&
            <>
            <TextField value={roundEditedValue} onChange={(e)=> setRoundEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setRoundEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('round')} variant='contained' sx={{width:100}}>{isRoundEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Round */}

            {/* RoundTrade */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isRoundTradeEdited ?'white':'grey'}}>RoundTrade</Typography>
                {!isRoundTradeEdited && <Typography>{wallet.roundTrade}</Typography>}
              </Box>
                {!isRoundTradeEdited && <IconButton onClick={()=>setRoundTradeEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isRoundTradeEdited &&
            <>
            <TextField value={roundTradeEditedValue} onChange={(e)=> setRoundTradeEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setRoundEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('roundTrade')} variant='contained' sx={{width:100}}>{isRoundTradeEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END RoundTrade */}

            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
    </>
  )

}