import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation} from 'react-router-dom'
import { useAuth } from './useAuth'
import { ButtonNavigation, Header } from '../component'
import { Box, Container, IconButton } from '@mui/material'
import { StateContextProvider, WebsocketContextProvider } from '../context'
import backgroundImg from '../asset/image/Manavola.webp'
import { SidebarDrawer } from '../component/admin'
import { IoMenu } from "react-icons/io5"


export const RequireAuthAdmin = () => {

    const auth = useAuth()
    const location = useLocation()

    const [toLogin,setToLogin] = useState(false)

    useEffect(()=>{
        if(auth.isAuthenticated !== null && auth.isAuthenticated=== false){
          setToLogin(true)
        }
    },[auth.isAuthenticated])

    //for side bar
    const [toggled, setToggled] = useState(false)
    const [collapsed, setCollapsed] = useState(false)

  return (
    <>
    { auth.isAuthenticated  && auth?.user !== null &&
            <>

        <WebsocketContextProvider userId={auth.user.id}> 
            <StateContextProvider>
              
              <Box sx={{
                  position:'realtive'
                }}>
                  <Box component={'img'} src={backgroundImg} width={'65%'} sx={{position:'absolute', display:{xs:'flex',md:'none'}, zIndex:-5,right:0,top:40, opacity:'2%'}}/>

                  <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                  <SidebarDrawer toggled={toggled} setToggled={setToggled} collapsed={collapsed} setCollapsed={setCollapsed}/>
                  <main style={{ width:'100%' }}>
                    
                    <Container>
                      <IconButton sx={{
                        display:{xs:'flex',sm:'none'}
                      }} onClick={()=>setToggled(!toggled)}>
                        <IoMenu/>
                      </IconButton>
                      <Outlet/>
                    </Container>
                  </main>
                </div>
                </Box>
            </StateContextProvider>
            </WebsocketContextProvider>
            </>
    }
    { toLogin &&
      <Navigate to='/login' state={{from: location}} replace /> 
    }
    </>
  )
}

///<Navigate to='/login' state={{from: location}} replace /> 