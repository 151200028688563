import { useQueries } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react'
import { axiosClient, useAxios } from '../utils';



const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {

    const axiosInstance = useAxios()

    ///api/tradetransaction'
    ///api/transaction

    const queries = [
      {
        queryKey: ['wallet'],
        queryFn: () => axiosClient.get('wallet'),
      },
      {
        queryKey: ['balance'],
        queryFn: () =>   axiosInstance.get('/api/balance'),
        //axiosClient.get('trade'),
      },
      {
        queryKey: ['api/tradetransaction'],
        queryFn: () =>   axiosInstance.get('api/tradetransaction'),
        //axiosClient.get('trade'),
      },
      {
        queryKey: ['api/transaction'],
        queryFn: () =>   axiosInstance.get('api/transaction'),
        //axiosClient.get('trade'),
      },
      // Add more queries as needed
    ];
  
    const results = useQueries({ queries })
  
    const [wallets, balances,tradetransactions,transactions] = results

   
    return (
      <WalletContext.Provider
      value={{
        wallets, balances,tradetransactions,transactions
      }}
      >
          {children}
      </WalletContext.Provider>
    )
}

export const useWalletContext = () => useContext(WalletContext);