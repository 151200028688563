import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { numberFormatter, roundNumber } from '../../utils'
import { convertDayJs, getTrade } from '../../utils/service'

export const HistoryByWallet = ({walletId,transaction,round,trade}) => {


    let typeT = transaction.type
    let mifamadika = false
    let t

    if(transaction.type === 'buy' || transaction.type === 'sell'){
        t = getTrade(trade,transaction.baseTradeId)
       //console.log(transaction.baseTradeId)
       //console.log(t)
       if(walletId === t.id){
        mifamadika= true
          if(transaction.type === 'buy'){
            typeT= 'sell'
          } else {
            typeT = 'buy'
          }
       }
    }
    

    const type = () => {
        if(typeT === 'buy'){
            if(walletId )
            return 'Acheter'
        } 
        else if(typeT === 'sell'){
          return 'Vendre'
        }
        else {
          return transaction.type
        }
    }
    

  return (
        <Paper sx={{
            display: {xs:'flex',sm:'flex'},
            flexDirection: 'row',
            width: '100%',
            padding: 1,
            my:1,
            px:3,
        
            //borderBottom: '1px solid #ccc',
            alignItems: 'center',
            justifyContent:'space-between'
          }}>
            <Box display={'flex'} alignItems={'center'} >
                <Box ml={0}>
                <Box display={'flex'} alignItems={'center'}>
                    <Typography >{type()}</Typography>
                    {(typeT === 'buy' || typeT === 'sell') && <Typography sx={{ml:1,color:'grey'}}>{t.compatibleWallet.symbol+'/'+t.symbol }</Typography>}
                </Box>
                <Typography sx={{fontStyle:'italic',color:'grey'}}>{convertDayJs(transaction.date)}</Typography>
                </Box>
            </Box>
    
            <Box sx= {{ display:'flex', flexDirection: 'column' , alignItems:'end',mr:3 }}>
              <Typography sx={{
                color: typeT === 'buy' || typeT === 'depot' ?  'green': '#dc3545',
                fontWeight: 'bold',
              }}>
                {numberFormatter.format(
                        roundNumber( 
                          typeT === 'buy' || typeT === 'sell' ? 
                            typeT === 'buy' ? 
                              mifamadika ? transaction.pairAmount:transaction.amount : 
                              mifamadika ? -transaction.pairAmount: -transaction.amount 
                            : transaction.type === 'depot' ? transaction.balanceRequested : -transaction.balanceRequested,
                          round
                  ))
                  }
              </Typography>
              {/* currentBaseWallet !== symbol &&
              <Typography sx={{color:'grey', fontStyle:'italic', fontSize:13}}>
                ≃ 
                {numberFormatter.format(
                        roundNumber( 
                          valueEquivalent,
                          currentBaseWallet === 'MGA' ? 0 : roundTrade
                  ))
                }
                {currentBaseWallet}
              </Typography>
              */}
            </Box>
          </Paper>
  )
}
