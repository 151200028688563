import { useQueries } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react'
import { axiosClient } from '../utils';

const cc= require('cryptocompare')
cc.setApiKey('b36bd91ed0a30ceaf6c090a5cf0cb352394f97ba99bc318e62e5896550046257')

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {

    const [price,setPrice] = useState([])
    //fetch core price

    const queries = [
      {
        queryKey: ['wallet'],
        queryFn: () => axiosClient.get('wallet'),
      },
      // Add more queries as needed
    ];
  
    const results = useQueries({ queries })
  
    const [wallets] = results

    // it is still wallet
    const [w,setW] = useState([])

    useEffect(()=>{

    if(wallets.isSuccess && wallets.data.data !== undefined){
      setW(wallets.data.data)
    }
    },[wallets])

    
useEffect(()=>{
    const fetchPrice = () => {
      const walletActif = w.filter(item=>item.categoryId !== 2)
      
      const symbolActif = walletActif.map((item)=> {return item.symbol})

      //console.log('e',symbolActif)
      
      cc.priceMulti(symbolActif,'USD')
      .then((price) => {
          const priceData = Object.entries(price).map(([key, value]) => {
          return { [key]: value.USD }
          })
          setPrice(priceData)
          console.log(priceData)
      })
      .catch(()=>console.log("can't fetch core price"))
  }

    if(w.length > 0){
      //console.log('ato')
      fetchPrice()
    }
},[w])


  return (
    <StateContext.Provider
      value={{
        price
      }}
    >
        {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);