import { Box, Button, Container, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { WalletShow } from '../component'
import { axiosClient, numberFormatter, roundNumber, useAxios } from '../utils'
import { priceService } from '../utils/service'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useStateContext, useWalletContext } from '../context'
import { SimpleMenu } from '../component/menu/SimpleMenu'
import { Histories } from './Histories'

export const Wallet = () => {
  const [tabActive,setTabActive] = useState('wallet')

  const [searchParams, setSearchParams] = useSearchParams()

  let isHistory = searchParams.get('current')

  useEffect(()=>{
    if(isHistory === 'histories'){
      setTabActive('histories')
    }
  },[])
  

  const axiosInstance = useAxios()

  const queries = [
    
    {
      queryKey: ['priceTrade'],
      queryFn: () => axiosClient.get('priceTrade'),
    },
    {
      queryKey: ['api/trade'],
      queryFn: () =>   axiosInstance.get('/api/trade'),
      //axiosClient.get('trade'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [priceTrade,trade] = results

  const {wallets, balances} = useWalletContext()

  const handleChangeTab = (e,newValue) =>{
    setTabActive(newValue)

    if(newValue === 'histories'){
      setSearchParams({ current: newValue});
    } else {
      setSearchParams({ });
    }
  }
  
  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab value="wallet" label="Mes Actifs" />
          <Tab value="histories" label="Historique" />
        </Tabs>
      </Box>
      { tabActive === 'wallet' &&
      <>
        
      {( wallets.isLoading
        || wallets.data.data === undefined
        || balances.isLoading
        || balances.data.data === undefined
        || trade.isLoading
        || trade.data.data === undefined
        || priceTrade.isLoading
        || priceTrade.data.data === undefined
        ) && <TwoBarContentLoader/>}

      { wallets.isSuccess
        && wallets.data.data !== undefined
        && balances.isSuccess
        && balances.data.data !== undefined
        && trade.isSuccess
        && trade.data.data !== undefined
        && priceTrade.isSuccess
        && priceTrade.data.data !== undefined
        && 
        <WalletActif wallets={wallets.data.data} balances={balances.data.data} trade={trade.data.data} priceTrade={priceTrade.data.data}/> 
      }
      
      </>
      }


    { tabActive === 'histories' &&
    <>
      <Histories/>
    </>
    }
    </Container>
  )
}

const WalletActif = ({wallets, balances, trade, priceTrade}) => {

  const navigate = useNavigate()
  const {price} = useStateContext()

 //console.log('p',price)
  const [currentBaseWallet,setCurrentBaseWallet] = useState('MGA')
  const [menuOpen,setMenuOpen] = useState(false)
  const [totalBalance,setTotalBalance] = useState(0)
  const [sortedWallet,setSortedWallet] = useState([])

  useEffect(()=>{
    if( Object.keys(price).length>0){
      let balance=0
      console.log('priceTrade',priceTrade)
      balances.map((item)=>{
        const w = wallets.find(w => w.id === item.walletId)
        //console.log(price)
        if(w.symbol !== 'MGA'){
          //console.log('bal',balance)
          //console.log(item)
          balance= balance + (priceService(currentBaseWallet,w.symbol,price,priceTrade).lastPrice*item.balance)
        } else {
          if(currentBaseWallet==='MGA'){
            balance=balance+item.balance
          } else {
            const priceTr = priceTrade.find(p=>p.pairSymbol===currentBaseWallet)
            const lastPrice = (priceTr.sellPrice+priceTr.buyPrice)/2            
            balance=balance+(item.balance/lastPrice)
          }
        }
      })
      setTotalBalance(balance)
    } 
    
  },[price,currentBaseWallet])

  //array of baseWallet
  const baseWallet = trade.map((i)=>i.symbol)
  
  //arrange wallet based on balance
  useEffect(()=>{
    if( Object.keys(price).length>0)
      {// Step 1: Create a dictionary to look up wallet balances by walletId
      const balanceByWalletId = balances.reduce((acc, balanceObj) => {
        acc[balanceObj.walletId] = balanceObj.balance;
        return acc;
      }, {});

      // Step 2: Create a dictionary to look up prices by symbol
      const priceBySymbol = price.reduce((acc, priceObj) => {
        const [symbol, priceValue] = Object.entries(priceObj)[0];
        acc[symbol] = priceValue;
        return acc;
      }, {});

      // Step 3: Add the USD balance to each wallet and store in a new array
      const walletWithBalance = wallets.map(walletItem => {
        const balance = balanceByWalletId[walletItem.id] || 0;  // Default to 0 if no balance found
        const conversionRate = priceBySymbol[walletItem.symbol] || 1; // Default to 1 if no price found
        const usdBalance = balance * conversionRate; // Calculate balance in USD

        // Return the wallet item with a new property 'usdBalance'
        return {
            ...walletItem,
            usdBalance
        };
      });

      // Step 4: Sort wallets by USD balance in descending order
      const sortedWallets = walletWithBalance.sort((a, b) => b.usdBalance - a.usdBalance);

     // console.log('sorted',sortedWallets);
      setSortedWallet(sortedWallets)
    }

        },[wallets,price,balances])

  return (
    <>
    <Typography mt={3}>
        Solde Total
    </Typography>
    <Box sx={{display:'flex',alignItems:'center'}}>
      <Typography sx={{
          fontSize:18,
          fontWeight:'bold',
        }}>
          {numberFormatter.format(roundNumber(totalBalance,2))}
      </Typography>
      <SimpleMenu 
        open={menuOpen} 
        setOpen={setMenuOpen} 
        state={currentBaseWallet} 
        setState={setCurrentBaseWallet}
        options={baseWallet}
        sx={{fontSize:13}}
        />
    </Box>

    <Box display={'flex'} mt={2}>
      <Button variant='outlined' sx={{mr:1}} onClick={()=>{navigate('/transaction')}}>
        Depot
      </Button>
      <Button variant='outlined' sx={{ml:1}} onClick={()=>{navigate('/transaction/retrait')}}>
        Retrait
      </Button>
    </Box>

    <Typography mt={3}>Les actifs dans mon portefeuille</Typography>
    <Stack spacing={1} mt={3}>
      {
        sortedWallet.map((item,index)=>{
          const balance = balances.find(i => i.walletId === item.id)
          //console.log('item',item)
          return (
            <Box  key={index} onClick={()=>navigate('/wallet/detail/'+item.id)}>
              <WalletShow img={item.icon} name={item.name} symbol={item.symbol} value={balance.balance} priceTrade={priceTrade} round={item.round} roundTrade={item.roundTrade} currentBaseWallet={currentBaseWallet}/>
            </Box>)
        })
      }
    </Stack>
    </>
  )
}