import { Box, Button, Grid, Typography } from '@mui/material'
import { useQueries } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useWalletContext } from '../context'
import { axiosClient, useAxios } from '../utils'
import { TwoBarContentLoader } from '../component/contentLoading'
import { getTrade, mixByDate } from '../utils/service'
import { HistoryByType } from '../component/ui'

export const Histories = () => {

    const [active,setActive] = useState('tous')

    const handleActiveChange = (newValue) => {
        setActive(newValue)
    }

    const axiosInstance = useAxios()

  const queries = [
    
    {
      queryKey: ['priceTrade'],
      queryFn: () => axiosClient.get('priceTrade'),
    },
    {
      queryKey: ['api/trade'],
      queryFn: () =>   axiosInstance.get('/api/trade'),
      //axiosClient.get('trade'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [priceTrade,trade] = results

  const {wallets, balances,tradetransactions,transactions} = useWalletContext()

  return (
    <Grid container sx={{display:'center', justifyContent:'center'}}>
        <Grid item xs={12} md={6}>

        
    <Box sx={{my:2}}>

        {/* BUTTON NAVIGATION */}
        <Box sx={{display:'flex',justifyContent:'center',mb:2}}>
            <Button
                onClick={()=>handleActiveChange('tous')} 
                variant={active === 'tous' ? 'contained':'outlined'} 
                sx={{
                    mx:0.5
                }}>
                    Tous
            </Button>
            <Button
                onClick={()=>handleActiveChange('dépôt')} 
                variant={active === 'dépôt' ? 'contained':'outlined'} 
                sx={{
                    mx:0.5
                }}>
                    dépôt
            </Button>
            <Button
                onClick={()=>handleActiveChange('retrait')} 
                variant={active === 'retrait' ? 'contained':'outlined'} 
                sx={{
                    mx:0.5
                }}>
                    retrait
            </Button>
            <Button
                onClick={()=>handleActiveChange('buy')} 
                variant={active === 'buy' ? 'contained':'outlined'} 
                sx={{
                    mx:0.5
                }}>
                    Acheter
            </Button>
            <Button
                onClick={()=>handleActiveChange('sell')} 
                variant={active === 'sell' ? 'contained':'outlined'} 
                sx={{
                    mx:0.5
                }}>
                    Vendre
            </Button>
        </Box>

        {/*END BUTTON NAVIGATION */}


        <>
        {( wallets.isLoading
            || wallets.data.data === undefined
            || balances.isLoading
            || balances.data.data === undefined
            || trade.isLoading
            || trade.data.data === undefined
            || priceTrade.isLoading
            || priceTrade.data.data === undefined
            || tradetransactions.isLoading
            || tradetransactions.data.data === undefined
            || transactions.isLoading
            || transactions.data.data === undefined
            ) && <TwoBarContentLoader/>}

        { wallets.isSuccess
            && wallets.data.data !== undefined
            && balances.isSuccess
            && balances.data.data !== undefined
            && trade.isSuccess
            && trade.data.data !== undefined
            && priceTrade.isSuccess
            && priceTrade.data.data !== undefined
            && tradetransactions.isSuccess
            && tradetransactions.data.data !== undefined
            && transactions.isSuccess
            && transactions.data.data !== undefined
            && 
            <HistoriesComponent 
                active={active}
                wallets={wallets.data.data} 
                balances={balances.data.data} 
                trade={trade.data.data} 
                priceTrade={priceTrade.data.data}
                tradetransactions={tradetransactions.data.data}
                transactions={transactions.data.data}
            /> 
        }
        </>
    </Box>
    </Grid>
    </Grid>
  )
}

const HistoriesComponent = ({active,wallets, balances, trade, priceTrade,tradetransactions,transactions}) => {

    //all
    const allHistories = mixByDate(tradetransactions,transactions)
    const [histories,setHistories] = useState(allHistories)
    
    useEffect(()=>{
        if(active === 'tous'){
            setHistories(allHistories)
        } else if(active === 'buy'){
            setHistories(tradetransactions.filter(t => t.type === 'buy'))
        }
        else if(active === 'sell'){
            setHistories(tradetransactions.filter(t => t.type === 'sell'))
        }
        else if(active === 'dépôt'){
            setHistories(transactions.filter(t => t.type === 'depot'))
        }
        else if(active === 'retrait'){
            setHistories(transactions.filter(t => t.type === 'retrait'))
        }
    },[active])
    //console.log(trade)

    return (
        <>
        {
            histories.map((item,index)=>{
                let w
                if(item.type === 'buy' || item.type === 'sell'){
                     w = getTrade(trade,item.baseTradeId).compatibleWallet
                    //console.log('w',t)
                    //const w = wallets.find(w=>w.id === t.compatible)
                } else {
                     w = wallets.find(w=>w.id === item.walletId)
                    //console.log('w',w)
                }
                return ( 
                    <HistoryByType currentType={active} transaction={item} wallet={w}/> 
                )
            })
        }

        {  histories.length === 0 && <Typography align='center' sx={{color:'grey',fontStyle:'italic'}}>Pas encore de transaction</Typography>

        }
        </>
    )
}
