import React, { createContext, useEffect, useState } from 'react'
//import axios from 'axios'
import { useNavigate } from 'react-router-dom'
//import axios from 'axios'

export const AuthContext= createContext()

export const AuthContextProvider = ({children}) => {

    const [user,setUser] = useState(null)
    const [token,setToken] = useState(null)
    const [refreshToken,setRefreshToken] = useState(null)
    const [isAuthenticated, setAuthenticated] = useState(null)
    const [isEmailVerified, setEmailVerified] = useState(null)

    const navigate = useNavigate()

    const handleLogin = (user,token,refreshToken) => {

        setUser(user)
        setToken(token)
        setRefreshToken(refreshToken)
        setAuthenticated(true)

        setEmailVerified(user.emailVerificationStatus)
        //setEmailVerified(true)
        //setEmailVerified(false)
        /*if(emailVerificationStatus){
            setAuthenticated(true)
        } else {
            setAuthenticated(false)
        }*/

        //save everything in local storage
        localStorage.setItem(
            'userData' , 
            JSON.stringify({
            user,
            isAuthenticated,
            }) 
        )
        localStorage.setItem(
            'authData' , 
            JSON.stringify({
            token,
            refreshToken,
            }) 
        )

        if(user.emailVerificationStatus){
            navigate('/')
        } else {
            navigate('/emailVerification')
        }
        
    }
    const reloadAuthData = (token,refreshToken) => {
        setToken(token)
        setRefreshToken(refreshToken)
    }

    //on reload check if the user is still logged in in server
    const reload = (user,token,refreshToken,emailVerificationStatus) => {
        
        setUser(user)
        setToken(token)
        setRefreshToken(refreshToken)
        setAuthenticated(true)
        setEmailVerified(user.emailVerificationStatus)

       /* if(!emailVerificationStatus){
            navigate('/emailVerification')
        }*/


    }

    //reload for comfirming mail
    const reloadUser = () =>{
        //save everything in local storage
        //console.log('user',user)


        const storedUserData = JSON.parse(localStorage.getItem('userData'))

        let userD

        if(storedUserData.user){

            userD = storedUserData.user
            userD.emailVerificationStatus= true  
            console.log('u',userD)
            //alert('m')
            setEmailVerified(true)
            setUser(userD)
        }

        localStorage.setItem(
            'userData' , 
            JSON.stringify({
            userD,
            isAuthenticated,
            }) 
        )

        navigate('/')
    }

    const handleLogout = () => {
        
           setUser(null)
           setToken(null)
           setRefreshToken(null)
           setAuthenticated(false)
           setEmailVerified(false)
           localStorage.removeItem('userData')
           localStorage.removeItem('authData')
       }
       
           //when the user reload the page 
           useEffect(()=>{
             const storedUserData = JSON.parse(localStorage.getItem('userData'))
             const storedAuthData = JSON.parse(localStorage.getItem('authData'))
             if(storedUserData && storedUserData.user && storedAuthData && storedAuthData.token && storedAuthData.refreshToken){
               //setAuthenticated(true)
               console.log(storedUserData)
               if(Object.keys(storedUserData.user)<3){
                handleLogout()
               }
               reload(storedUserData.user, storedAuthData.token,storedAuthData.refreshToken,storedUserData.emailVerificationStatus) 

             } else {
                setAuthenticated(false)
                setEmailVerified(false)
             }
        }, [])
     
        return (
          <AuthContext.Provider value={{user,setUser,token,refreshToken,isAuthenticated,isEmailVerified,handleLogin,reloadAuthData,reloadUser,handleLogout}}>
              {children}
          </AuthContext.Provider>
        )
}