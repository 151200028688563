import React, { useState } from 'react'
import { axiosClient, backendUrl, numberFormatter, roundNumber } from '../utils';
import { TwoBarContentLoader } from '../component/contentLoading';
import { useQueries } from '@tanstack/react-query';
import { Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { Title } from '../component/admin'
import { MdEdit } from 'react-icons/md'
import { DialogComponent } from '../component/dialog';
import { CiBoxes } from 'react-icons/ci';
import { SnackBarStatus } from '../component';
import { useStateContext } from '../context';
import { priceService } from '../utils/service';


export const RateTrade = () => {
    const queries = [
        {
          queryKey: ['trade'],
          queryFn: () => axiosClient.get('trade'),
        },
        {
          queryKey: ['priceTrade'],
          queryFn: () => axiosClient.get('priceTrade'),
        }
      ];
      const results = useQueries({ queries })

      const [trade, priceTrade] = results
    
      return (
        <div>{(
         trade.isLoading
        || trade.data.data === undefined
        || priceTrade.isLoading
        || priceTrade.data.data === undefined
        
        ) && <TwoBarContentLoader/>}
    
      { 
         trade.isSuccess
        && trade.data.data !== undefined
        && priceTrade.isSuccess
        && priceTrade.data.data !== undefined
        && 
      <RateTradeLayout 
        tradeObject={trade}
        trade={trade.data.data}
        priceTradeObject={priceTrade}
        priceTrade={priceTrade.data.data}
        />
      }
     </div>
      )
}

export const RateTradeLayout = ({trade, tradeObject, priceTrade, priceTradeObject}) => {

    //console.log(trade)


    const {price} = useStateContext()

    //for 
    const [buyPrice, setBuyPrice] = useState('')
    const [sellPrice,setSellPrice] = useState('') 
    const [open,setOpen] = useState(false)



    const [isLoading, setIsLoading]= useState(false)
    const [status,setStatus] = useState(false)
    const [snackbar,setSnackbar] = useState(null)

    const [currentTrade, setCurrentTrade] = useState({
        symbol:'null',
        compatibleWallet:{
            symbol:'null'
        }})


    const getCurrentCrypto= (id) => trade
    .map((item) => {
          //console.log(item.name,trade)
        const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == id);
        if (wallet) {
        return {
            id: item.id,
            name: item.name,
            symbol: item.symbol,
            icon: item.icon,
            compatibleWallet: wallet,
        };
        }
        return null;
    })
    .find((item) => item !== null);


    const openDialog = (priceTrade) => {
        
        console.log(priceTrade)

        //const crypto = getCurrentCrypto(id)

        setCurrentTrade(priceTrade)

        setBuyPrice(priceTrade.buyPrice)
        setSellPrice(priceTrade.sellPrice)

        //console.log(crypto.compatibleWallet.buyRate)
        setOpen(true)
    }

    const handleChangePrice = () =>{

        setIsLoading(true)
        const formData = new FormData()
        formData.append('buyPrice',buyPrice)
        formData.append('sellPrice',sellPrice)
        axiosClient.post('/priceTrade/'+currentTrade.id,formData,{
            transformRequest: dataForm=> dataForm,//to solve axios issue
        })
        .then((resp)=>{     
            priceTradeObject.refetch()
            //tradeObject.refetch()  
            setSnackbar({children:'Modification avec success', severity: 'success'})
            setStatus(true)
            setIsLoading(false)
            setOpen(false)
        })
        .catch((e)=>{
            console.log(e)
            setSnackbar({children:'Erreur sur la modification', severity: 'error'})
            setStatus(true)
            setIsLoading(false)
        })
    }

    return(
        <Container>
            <Title title={'Cours Trade'}/>
           {/* DIALOGUE */}
           <DialogComponent 
            open={open} 
            setOpen={setOpen} 
            title={currentTrade.pairSymbol+'/'+currentTrade.mainSymbol}
            action={
                    <>
                    <Button variant='outlined' onClick={()=>setOpen(false)}>Annuler</Button>
                    <Button variant='contained' onClick={handleChangePrice}>
                        Enregistrer

                { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Enregistrer"}
                    </Button>
                    </>
                    }
            >
                <Box sx={{
                   // width: {xs:'80vw',md:500},
                }}>
                    <TextField sx={{my:1}} label='Prix Acheter' value={buyPrice || ''} onChange={(e)=>setBuyPrice(e.target.value)} fullWidth/>
                    <TextField label='Prix Vendre' value={sellPrice || ''} onChange={(e)=>setSellPrice(e.target.value)} fullWidth/>
                </Box>
            </DialogComponent>
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
            {/* END DIALOGUE */}
            <Grid container mt={3}>
            <Grid item xs={12} md={7}>

            { Object.keys(price).length> 0 && 
                priceTrade.map((item,index)=>{
                    const priceT = priceService(item.mainSymbol,item.pairSymbol,price,priceTrade)
                    //console.log(priceT)
                    return (
                        <Paper key={index} sx={{
                            // display: {xs:'flex'}, //,sm:'none'
                            // flexDirection: 'row',
                             width: '100%',
                             padding: 1,
                             my:1,
                             //my:1,
                         
                             //borderBottom: '1px solid #ccc',
                            // alignItems: 'center',
                             //justifyContent:'center'
                         }}>

                            <Box key={index} sx={{p:1,width:'100%'}}>
                            <Typography>{item.pairSymbol}/{item.mainSymbol}</Typography>
                            <Box 
                            onClick={()=>openDialog(item)}
                            sx={{
                                display:'flex',
                                alignItems:'end',
                                width:'100%',
                                overflowX:'auto',
                                whiteSpace: 'nowrap',// Prevents wrapping of content
                                my:1,
                                '&:hover':{
                                    backgroundColor:'#ffffff11',
                                    cursor:'pointer',
                                    borderRadius:2
                                }}}>
                                <IconButton sx={{display:{xs:'none',md:'flex'},width:'10%',mx:0.1}}>
                                    <MdEdit size={20}/>
                                </IconButton>
                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Prix Vendre</Typography>
                                    <Typography sx={{ml:1}}>{priceT.sellPrice} {item.mainSymbol}</Typography>
                                </Box>

                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Prix Acheter</Typography>
                                    <Typography sx={{ml:1}}>{priceT.buyPrice} {item.mainSymbol}</Typography>
                                </Box>
                                <Box sx={{width:{xs:'15vw',md:'15%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Spread</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(roundNumber(priceT.spread,2))}%</Typography>
                                </Box>

                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Dernier Prix</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(priceT.lastPrice)} {item.mainSymbol}</Typography>
                                </Box>
                            </Box>
                            <Divider/>
                        </Box>

                        </Paper>
                    )
                })
            }
            {trade.map((item,index)=>{
                return (
                <Box key={index}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <img src={backendUrl+'image/logo/'+item.icon} alt={item.name} width={30} height={30}/>
                        <Typography variant='h6' sx={{ml:1, fontWeight:'bold', mt:2,mb:1}}>{item.name.toUpperCase()}</Typography>
                    </Box>

                    <Paper key={index} sx={{
                           // display: {xs:'flex'}, //,sm:'none'
                           // flexDirection: 'row',
                           // width: '100%',
                            padding: 1,
                            //my:1,
                        
                            //borderBottom: '1px solid #ccc',
                           // alignItems: 'center',
                            //justifyContent:'center'
                        }}>
                    { Object.keys(price).length> 0 && 
                    item.compatibleWallet.map((value,index)=>{
                        const priceT = priceService(item.symbol,value.symbol,price,priceTrade)
                        return (    
                        <Box key={index} sx={{p:1,width:'100%'}}>
                            <Typography>{value.symbol}/{item.symbol}</Typography>
                            <Box 
                            //onClick={()=>openDialog(value.tradeId)}
                            sx={{
                                display:'flex',
                                alignItems:'end',
                                width:'100%',
                                overflowX:'auto',
                                whiteSpace: 'nowrap',// Prevents wrapping of content
                                my:1,
                                '&:hover':{
                                    backgroundColor:'#ffffff11',
                                    cursor:'pointer',
                                    borderRadius:2
                                }}}>
                                <IconButton sx={{display:{xs:'none',md:'flex'},width:'10%',mx:0.1}}>
                                    <MdEdit size={20}/>
                                </IconButton>
                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Prix Vendre</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(roundNumber(priceT.sellPrice,4))} {item.symbol}</Typography>
                                </Box>

                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Prix Acheter</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(roundNumber(priceT.buyPrice,4))} {item.symbol}</Typography>
                                </Box>
                                <Box sx={{width:{xs:'15vw',md:'15%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Spread</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(roundNumber(priceT.spread,2))}%</Typography>
                                </Box>

                                <Box sx={{width:{xs:'25vw',md:'28%'},mx:2}}>
                                    <Typography sx={{color:'grey'}}>Dernier Prix</Typography>
                                    <Typography sx={{ml:1}}>{numberFormatter.format(roundNumber(priceT.lastPrice,4))} {item.symbol}</Typography>
                                </Box>
                            </Box>
                            <Divider/>
                        </Box>
                        )
                    }
                )}
                </Paper>
                </Box>
                )
            })}
            </Grid>
            </Grid>
        </Container>
    )
}
/*

 


*/