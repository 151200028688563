import React, { useEffect, useState } from 'react'
import { useStateContext } from '../context'
import { priceService } from '../utils/service'
import { numberFormatter, roundNumber } from '../utils'

export const PriceEquivalent = ({value,symbol, currentBaseWallet,priceTrade,roundTrade}) => {
    const {price} = useStateContext()

    const [valueEquivalent,setValueEquivalent] = useState(0)

    useEffect(() => {
        if( Object.keys(price).length>0){
          if(symbol !== 'MGA'){
            //console.log('bal',balance)
            //console.log(item)
            setValueEquivalent(priceService(currentBaseWallet,symbol,price,priceTrade).lastPrice*value)
    
          } else {
            if(currentBaseWallet==='MGA'){
             setValueEquivalent(value)
            } else {
              const priceTr = priceTrade.find(p=>p.pairSymbol===currentBaseWallet)
              const lastPrice = (priceTr.sellPrice+priceTr.buyPrice)/2            
              setValueEquivalent(value/lastPrice)
            }
          }
        }
      },[currentBaseWallet,price])
    
  
  return numberFormatter.format(roundNumber(valueEquivalent,currentBaseWallet === 'MGA' ? 0 : roundTrade))
}
