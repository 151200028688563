import React, { useState } from 'react'
import { TwoBarContentLoader } from '../component/contentLoading';
import { useQueries } from '@tanstack/react-query';
import { axiosClient, backendUrl, numberFormatter, roundNumber, useAxios } from '../utils';
import { useStateContext, useWalletContext } from '../context';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { SimpleMenu } from '../component/menu/SimpleMenu';
import { PriceEquivalent } from '../component';
import { extractAllTradeId, mixByDate, priceService } from '../utils/service';
import { HistoryByWallet } from '../component/ui';

export const WalletDetail = () => {
    const axiosInstance = useAxios()

    const queries = [
        
        {
        queryKey: ['priceTrade'],
        queryFn: () => axiosClient.get('priceTrade'),
        },
        {
        queryKey: ['api/trade'],
        queryFn: () =>   axiosInstance.get('/api/trade'),
        //axiosClient.get('trade'),
        },
        // Add more queries as needed
    ];

    const results = useQueries({ queries })

    const [priceTrade,trade] = results

    const {wallets, balances,tradetransactions,transactions} = useWalletContext()

    return (
        <>
      {( wallets.isLoading
        || wallets.data.data === undefined
        || balances.isLoading
        || balances.data.data === undefined
        || trade.isLoading
        || trade.data.data === undefined
        || priceTrade.isLoading
        || priceTrade.data.data === undefined
        || tradetransactions.isLoading
        || tradetransactions.data.data === undefined
        || transactions.isLoading
        || transactions.data.data === undefined
        ) && <TwoBarContentLoader/>}

      { wallets.isSuccess
        && wallets.data.data !== undefined
        && balances.isSuccess
        && balances.data.data !== undefined
        && trade.isSuccess
        && trade.data.data !== undefined
        && priceTrade.isSuccess
        && priceTrade.data.data !== undefined
        && tradetransactions.isSuccess
        && tradetransactions.data.data !== undefined
        && transactions.isSuccess
        && transactions.data.data !== undefined
        && 
        <WalletDetailComponent 
            wallets={wallets.data.data} 
            balances={balances.data.data} 
            trade={trade.data.data} 
            priceTrade={priceTrade.data.data}
            tradetransactions={tradetransactions.data.data}
            transactions={transactions.data.data}
        /> 
      }
      </>
    )
}

const WalletDetailComponent = ({wallets, balances, trade, priceTrade,tradetransactions,transactions}) => {

    const { id } = useParams()

    const wallet = wallets.find(w => w.id == id)
    
    const balance = balances.find(i => i.walletId == id)


    const {price} = useStateContext()

  


    //console.log('hist',tradeIdByWallet)


    const navigate = useNavigate()


    //array of baseWallet
    const baseWallet = trade.map((i)=>i.symbol)


    const [currentBaseWallet,setCurrentBaseWallet] = useState('MGA')
    const [menuOpen,setMenuOpen] = useState(false)

    //for trade 
    const filterTradeForWallet = ()=>{

        const filteredF = trade.map(item => {
          // Check if 'u' is found in the main item's name or symbol
          const mainHasU = item.name.toLowerCase().includes(wallet.name.toLowerCase()) || item.symbol.toLowerCase().includes(wallet.symbol.toLowerCase());
      
          // If 'u' is found in the main item, keep all compatibleWallets
          if (mainHasU) {
              return item;
          }
      
          // Otherwise, filter the compatibleWallet array based on 'u' in their name or symbol
          const filteredCompatibleWallet = item.compatibleWallet.filter(w => 
              w.name.toLowerCase().includes(wallet.name.toLowerCase()) || w.symbol.toLowerCase().includes(wallet.symbol.toLowerCase())
          );
      
          // Return the item with the filtered compatibleWallet
          return { ...item, compatibleWallet: filteredCompatibleWallet }

      }).filter(item => 
          // Only return items that have 'u' in main name/symbol or have non-empty compatibleWallets
          item.compatibleWallet.length > 0 || item.name.toLowerCase().includes(wallet.name.toLowerCase()) || item.symbol.toLowerCase().includes(wallet.symbol.toLowerCase())
      );
  
     return filteredF
    }

    const filteredTrade = filterTradeForWallet() 

    console.log("fil",extractAllTradeId(filteredTrade))


    //mixins histories

    const tradeIdByWallet = extractAllTradeId(filteredTrade)


    console.log('bal',tradeIdByWallet,trade)
    const filter = {
        walletId: wallet.id,
        tradeId: tradeIdByWallet
    }

    const histories = mixByDate(tradetransactions,transactions,filter)


    return (
    <Container>
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6}>

        {/* heading wallet */}
        <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>

            <img src={backendUrl+'image/logo/'+wallet.icon} alt={'wallet'} width={40} height={40} />
            <Typography variant='h6' sx={{fontWeight:'bold', ml:1}}>{wallet.name}</Typography>
        </Box>

        {/* End heading wallet */}


        {/* solde wallet */}
        <Typography mt={3}>
        Solde 
        </Typography>
        <Box sx={{display:'flex',alignItems:'center'}}>
        <Typography sx={{
            fontSize:18,
            fontWeight:'bold',
            }}>
            {numberFormatter.format(roundNumber(balance.balance,wallet.round))}
        </Typography>
        <Typography sx={{
            fontSize:18,
            fontWeight:'bold',
            ml:1
            }}>
            {wallet.symbol}
        </Typography>
        </Box>
        <Box sx={{display:'flex', alignItems:'center',ml:1}}>
            <Typography sx={{fontSize:13,fontStyle:'italic',color:'grey'}}>
            ≃ <PriceEquivalent value={balance.balance} symbol={wallet.symbol}  currentBaseWallet={currentBaseWallet} priceTrade={priceTrade} roundTrade={wallet.roundTrade} />
            </Typography>

            <SimpleMenu 
            open={menuOpen} 
            setOpen={setMenuOpen} 
            state={currentBaseWallet} 
            setState={setCurrentBaseWallet}
            options={baseWallet}
            sx={{fontSize:13,fontStyle:'italic',color:'grey'}}
            />
        </Box>

        {/* end  solde wallet */}

        {/* NAvigation wallet */}
        <Box sx={{display: 'flex', justifyContent:'center',my:1}}>
            <Button variant='outlined' onClick={()=>navigate('/transaction/'+wallet.id)}>
                Depot
            </Button>
            <Button variant='outlined' sx={{ml:1}} onClick={()=>navigate('/transaction/retrait/'+wallet.id)}>
                Retrait
            </Button>
        </Box>
        {/*End  NAvigation wallet */}

        {/* Trading option */}
        <Box sx={{my:1}}>
            <Typography color={'primary'} sx={{fontWeight:'bold'}}>
                Trading
            </Typography>
            <Box sx={{display:'flex', overflowX:'auto',scrollbarColor:'blue'}}>
            {Object.keys(price).length>0 && filteredTrade.map((item,index)=>{
                return ( 
                <Box key={index} sx={{display:'flex', mx:0.5}}>
                    {item.compatibleWallet.map((i,index)=>{

                        const priceT = priceService(item.symbol,i.symbol,price,priceTrade)

                        return (
                        <Box key={index} onClick={()=>navigate('/trade?vr='+i.tradeId+'&tr=buy')}
                        sx={{
                            width:{xs:150,md:150},
                            mx:0.5,
                            cursor:'pointer',
                            
                            }}>
                            <Paper sx={{
                                p:1,
                                '&:hover': {
                                backgroundColor:'#ffffff11'
                            }
                            }}>
                                <Box>
                                    <Typography>{i.symbol}<Box component={'span'} sx={{color:'grey'}}>/{item.symbol}</Box></Typography>
                                    <Typography align='center' sx={{my:1}}>{numberFormatter.format(priceT.lastPrice)}</Typography>
                                </Box>
                            </Paper>
                        </Box>
                        )
                    })
                    }
                </Box>)
            })}
            </Box>
        </Box>
        {/* End Trading option */}

        {/* historique */}
        <Box  sx={{my:1}}>
            <Typography color={'primary'} sx={{fontWeight:'bold'}}>
                Historique
            </Typography>
            {
                histories.map((item,index)=>{
                    //console.log(item)
                    return <HistoryByWallet key={index} walletId={wallet.id} trade={trade} transaction={item} round={wallet.round}/>
                })
            }
        </Box>
        {/* end historique */}

        </Grid>
        </Grid>
    </Container>
    )

}
