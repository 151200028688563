
import React, { createContext, useContext, useState } from 'react'
import {  socketUrl } from '../utils';
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAuth } from '../auth';
import { Button } from '@mui/material';


const WebsocketContext = createContext();

export const WebsocketContextProvider = ({ userId,children }) => {


  //const {user} = useAuth()
  //console.log('websocket userId',userId)

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl+'/?userId='+userId,
    {
      shouldReconnect: (closeEvent) => true, // Reconnect on all close events
      reconnectAttempts: 10,                // Set the max number of reconnection attempts
      reconnectInterval: 3000               // Set the interval between attempts (e.g., 3000 ms)
    }
);

  const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

  const pushMessage =  (targetUserId,action,payload) => {
    const data = {
      userId: targetUserId,
      fromUserId: userId,
      action: action,
      data: payload
  }

  //return new Promise((resolve) => {
    sendMessage(JSON.stringify(data))
   // resolve()
  //})
  
  }

  //{readyState !== ReadyState.OPEN}
   
  return (
    <WebsocketContext.Provider
    value={{
      sendMessage,lastMessage, readyState , connectionStatus,pushMessage
    }}
    >
      <Button variant='contained' sx={{position:'fixed',zIndex:15,bottom:15}}
            disabled={readyState !== ReadyState.OPEN}>{connectionStatus}</Button>
        {children}
    </WebsocketContext.Provider>
  )
}

export const useWebsocketContext = () => useContext(WebsocketContext);