import React, { useState } from 'react'
import {DialogComponent} from './dialog'
import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'


import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormInput } from './form'
import { useAxios } from '../utils'
import { SnackBarStatus } from './SnackBarStatus'


export const AddNumber = ({open,setOpen,title, notation,regex,id,postAction}) => {


  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)
  const [isLoading,setIsLoading] = useState(false)


    const dataForm = new FormData()

    const axiosInstance = useAxios()

    const validationSchema = Yup.object().shape({
      name : Yup.string()
          .required("Veillez ajouter un nombre"),
      accountNumber: Yup.string()
      .required("Veillez entrer un numero de compte")
      .matches(new RegExp(regex),`Veuillez entrer ${notation} valide`),
    })
  
    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            name: '',
            accountNumber: undefined,
          },
      })
      
    const {handleSubmit,formState: { errors }} = methods
  
    //const selectedCategory = watch('paymentMethod')

      const onSubmit = (data) => {
        setIsLoading(true)
       //console.log(data)
        
      dataForm.append('accountNumber',data.accountNumber)
      dataForm.append('name',data.name)
      dataForm.append('paymentMethodId',id)
      
      //alert(data.accountNumber)
      axiosInstance.post('/api/walletAccountNumber',dataForm,
      {
          headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm => dataForm,//to solve axios issue
      })
      .then(resp => {
          postAction()
          setSnackbar({children:'Creation de nouveau numero de compte avec success', severity: 'success'})
          setIsLoading(false)
          setStatus(true)
          setOpen(false)
        //navigate('/login')
      })
      .catch(error => {
          setIsLoading(false)
          setSnackbar({children:'Erreur sur la creation de nouveau numero de compte ', severity: 'error'})
          setStatus(true)
          setOpen(false)
          //console.log(error.response.data.message)
          })
      }

  return (
    <>

    <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
    <DialogComponent open={open} setOpen={setOpen} title={"Ajout d'un nouveau numero de "+title}>
        <FormProvider {...methods}>
          <form>
            <Stack spacing={1}>
                <FormInput name='accountNumber' label={`Numéro de compte`} error={!!errors?.accountNumber} helperText={errors?.accountNumber?.message} fullWidth/>
                <FormInput name='name' label={`Nom correspondant`} error={!!errors?.name} helperText={errors?.name?.message} fullWidth/>
                
                <Box sx={{display:'flex', justifyContent:'end'}}>
                    <Button variant='outlined' onClick={()=>setOpen(false)}>Annuler</Button>
                    <Button variant='contained' sx={{ml:1}} onClick={handleSubmit(onSubmit)}>
                    { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Ajouter"}
                    </Button>
                </Box>
            </Stack>
          </form>
        </FormProvider>
    </DialogComponent>
    </>
  )
}
