import dayjs from "dayjs";


export const priceService = (baseWallet,compatibleWallet,price,priceTrade) => {
    const priceValue = price.find(obj => obj.hasOwnProperty(compatibleWallet))[compatibleWallet];
    //nim
    if(baseWallet === 'USDT'){
        const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')

        const dtm = (tm.buyPrice+tm.sellPrice)/2
        const buyPrice = tm.buyPrice/dtm*priceValue
        const sellPrice = tm.sellPrice/dtm*priceValue

        return {
            buyPrice: buyPrice,
            sellPrice: sellPrice,
            lastPrice: (buyPrice+sellPrice)/2,
            spread: (buyPrice-sellPrice)*100/buyPrice
        }
    } 
    else if (baseWallet === 'USD'){
        const um = priceTrade.find(i=>i.pairSymbol=== 'USD')
        const dum = (um.buyPrice+um.sellPrice)/2
        const btu = um.buyPrice/dum
        const stu = um.sellPrice/dum

        if (compatibleWallet === 'USDT'){
            return {
                buyPrice: btu,
                sellPrice: stu,
                lastPrice: (btu+stu)/2,
                spread: (btu-stu)*100/btu
            }
        }
        else { //crypto
            const buyPrice = btu*priceValue
            const sellPrice = stu*priceValue

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
    }
    else if(baseWallet === 'MGA') { //MGA

        if(compatibleWallet === 'USDT'){
            const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')
            //const dtm = (tm.buyPrice+tm.sellPrice)/2
            const buyPrice = tm.buyPrice
            const sellPrice = tm.sellPrice

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
        else if (compatibleWallet === 'USD'){
            const um = priceTrade.find(i=>i.pairSymbol=== 'USD')
            const buyPrice = um.buyPrice
            const sellPrice = um.sellPrice

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
        else { //crypto
            const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')
            //const dtm = (tm.buyPrice+tm.sellPrice)/2
            const buyPrice = tm.buyPrice*priceValue
            const sellPrice = tm.sellPrice*priceValue

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }

    }
}

export const mixByDate = (a,b,filter) => {

    console.log('filter',filter)

    if(filter !== undefined){

        if(filter.walletId){
            b=b.filter(i => i.walletId === filter.walletId)
        }
        if (filter.tradeId){
         a = a.filter(item => filter.tradeId.includes(item.baseTradeId));
        }
    }

    //b=filter.
    const allMixed = [...a, ...b].sort(
        (aa, bb) => new Date(bb.date) - new Date(aa.date)
    );

    return allMixed
}

export const convertDayJs = (date,format) => {

    if(format === undefined || format === null){
        format = 'YYYY-MM-DD HH:mm:ss'
    }

    return  dayjs(date).format(format)

    //dayjsInstance.format('YYYY-MM-DD HH:mm:ss')
} 

export const extractTradeIdByWalletId = (id, trade) => {
    return trade
    .filter(item => item.id === id || item.compatibleWallet.some(wallet => wallet.id === id))
    .flatMap(item =>
        item.id === id 
            ? [item.id] 
            : item.compatibleWallet.filter(wallet => wallet.id === id).map(wallet => wallet.tradeId)
    );
}

export const extractAllTradeId = (trade) => {
    return trade.flatMap(item => 
        item.compatibleWallet.map(wallet => wallet.tradeId)
    );
}

export const getTrade= (trade,basetradeId) => trade
  .map((item) => {
    
    const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == basetradeId);
    if (wallet) {
      return {
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        icon: item.icon,
        round: item.round,
        roundTrade: item.roundTrade,
        compatibleWallet: wallet,
      };
    }
    return null;
  })
  .find((item) => item !== null);